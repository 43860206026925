import React from "react";
import { useNavigate } from "react-router-dom";

import Container from "../../../components/container/Container";
import CustomButton from "../../../components/button/Button";
import { useDataContext } from "../../../contexts/DataContext";

import styled from "styled-components";

const Label = styled.label`
  display: inline-block;
  width: 100%;
  text-align: left;
  margin-bottom: 0.5em;
`;

const LabelCheckbox = styled(Label)`
  font-size: 1.1em;
`;

const Input = styled.input`
  font-size: 1.4em;
  width: 100%;
  border: 1px solid transparent;
  border-radius: 5px;
  background-color: #ebebeb;
  margin-top: 0.2em;
  padding: 0;
  height: 35px;
`;

const Textarea = styled.textarea`
  font-size: 1.4em;
  width: 100%;
  border: 1px solid transparent;
  border-radius: 5px;
  background-color: #ebebeb;
  margin-top: 0.2em;
  padding: 0;
  height: 150px;
  resize: vertical;
`;

const FieldSpan = styled.span`
  font-size: 1.1em;
  color: #888;
  margin-bottom: 4px;
`;

const MensagemForm = ({
  formData,
  setFormData,
  isEditar = false,
}) => {
  const { mutationPostMensagem, mutationPutMensagem } = useDataContext();
  const navigate = useNavigate();

  const tituloForm = isEditar ? `Editar` : `Cadastrar`

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChangeCheckbox = (e) => {
    const { name, checked } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: checked }));
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isEditar) {
      try {
        await mutationPutMensagem.mutateAsync(formData);
      } catch (error) {
        console.error("Erro ao editar notificação:", error);
      }
    } else {
      try {
        await mutationPostMensagem.mutateAsync(formData);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  return (
    <Container titulo={`${tituloForm} notificação`} style={{ marginTop: "10%" }}>
      <form onSubmit={handleSubmit}>
        <br />
        <br />
        <Label>
          Título:<span style={{ color: "red" }}>*</span>
          <Input
            type="text"
            name="titulo"
            value={formData.titulo}
            onChange={handleChange}
            required
          />
        </Label>
        <Label>
          Texto:<span style={{ color: "red" }}>*</span>
          <Textarea
            rows="6"
            name="corpo"
            value={formData.corpo}
            onChange={handleChange}
            required
          />
        </Label>
        <FieldSpan style={{display: 'flex'}}>Selecione destinatário:<span style={{ color: "red" }}>*</span></FieldSpan>
        <div style={{display: 'flex'}}>
          <LabelCheckbox>
            <input
              type="checkbox"
              name="destinaSecretario"
              checked={formData.destinaSecretario}
              onChange={handleChangeCheckbox}
            />
            Secretário
          </LabelCheckbox>
          <LabelCheckbox>
            <input
              type="checkbox"
              name="destinaAvaliador"
              checked={formData.destinaAvaliador}
              onChange={handleChangeCheckbox}
            />
            Avaliador
          </LabelCheckbox>
          <LabelCheckbox>
            <input
              type="checkbox"
              name="destinaMultiprofissional"
              checked={formData.destinaMultiprofissional}
              onChange={handleChangeCheckbox}
            />
            Multiprofissional
          </LabelCheckbox>
        </div>
        <div
          style={{
            display: "flex",
            gap: "30px",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <CustomButton type="submit">Salvar</CustomButton>
          <CustomButton variant="cancel" onClick={handleCancel}>
            Cancelar
          </CustomButton>
        </div>
      </form>
    </Container>
  );
};

export default MensagemForm;
