import React, { useState, useMemo } from "react";
import styled from "styled-components";
import SearchBar from "./SearchBar";
import ActionButtonTable from "./ActionButtonTable";
import TotalCount from "./TotalCount";
import { useNavigate } from "react-router-dom";
import { StyledSettingsIcon, StyledUpdateIcon } from "../../utils/StyledUtils";
import Tooltip from "../../components/tooltip/Tooltip";
import { MdOutlineAdd } from "react-icons/md";
import { useUserContext } from "../../contexts/UserContext";

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 1em;
  min-width: 400px;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
`;

const TableHead = styled.thead`
  background-color: #00acc1;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
`;

const TableBody = styled.tbody`
  border-bottom: 2px solid #0062bc;
`;

const TableRow = styled.tr`
  border-bottom: 1px solid #dddddd;
  &:nth-of-type(even) {
    background-color: #f3f3f3;
    &:hover {
      background-color: #d9d9d9;
    }
  }
  &:nth-of-type(odd) {
    background-color: #ebebeb;
    &:hover {
      background-color: #d9d9d9;
    }
  }
  &:last-of-type {
    border-bottom: 2px solid #0062bc;
  }
`;

const TableCell = styled.td`
  padding: 12px 15px;
  text-align: center;
`;

const TableHeaderCell = styled.th`
  background-color: #0062bc;
  padding: 12px 15px;
  text-align: center;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const ContainerSearchAndButton = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Paginacao = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  button {
    background-color: #0062bc;
    border: none;
    border-radius: 5px;
    color: white;
    padding: 10px 20px;
    margin: 0 5px;
    cursor: pointer;
    font-size: 16px;
  }

  button:disabled {
    color: black;
    background-color: #b1b0b0;
    cursor: not-allowed;
  }

  span {
    margin: 0 10px;
    font-size: 16px;
  }
`;

const Button = styled.button`
  border-radius: 15px;
  text-align: center;
  font-size: 20px;
  padding: 10px;
  color: #fff;
  background-color: #0062bc;
  border: none;
  height: 45px;
  cursor: pointer;
  transition: background 0.3s ease;

  &:hover {
    background-color: #0070d1;
  }

  &:disabled {
    background-color: #b0bec5;
    cursor: not-allowed;
  }
`;

const extractText = (element) => {
  if (React.isValidElement(element)) {
    return extractText(element.props.children);
  } else if (Array.isArray(element)) {
    return element.map(extractText).join(" ");
  }
  return element ? element.toString() : "";
};

const Tabela = ({
  data,
  header,
  paginated = false,
  search = [],
  actionButton,
  actionConfig,
  actionAdd,
  actionUpdate,
  buttonLabel,
  needTotal = true,
  handleUpdate,
}) => {
  const { isDisabledUpdate } = useUserContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig] = useState(null);
  const itemsPerPage = 15;
  const hasSearch = search.length !== 0;
  const navigate = useNavigate();

  const sorted = useMemo(() => {
    if (!sortConfig) return data;
    const { key, direction } = sortConfig;
    return [...data].sort((a, b) => {
      if (a[key] < b[key]) return direction === "ascending" ? -1 : 1;
      if (a[key] > b[key]) return direction === "ascending" ? 1 : -1;
      return 0;
    });
  }, [data, sortConfig]);

  const filteredData = useMemo(() => {
    if (!hasSearch || !searchTerm) return sorted;
    const searchText = searchTerm.toLowerCase();
    return sorted.filter((item) =>
      search.some((field) =>
        extractText(item[field]).toLowerCase().includes(searchText)
      )
    );
  }, [sorted, searchTerm, search, hasSearch]);

  const displayData = useMemo(() => {
    if (!paginated) return filteredData;
    const startIndex = (currentPage - 1) * itemsPerPage;
    return filteredData.slice(startIndex, startIndex + itemsPerPage);
  }, [currentPage, itemsPerPage, filteredData, paginated]);

  if (!data || data.length === 0) {
    return <p>Nenhum dado disponível.</p>;
  }

  const handleClick = (path) => {
    navigate(path);
  };

  const colunas = Object.keys(data[0]);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  return (
    <>
      <Container>
        <ContainerSearchAndButton>
          {hasSearch && (
            <SearchBar
              onChange={(event) => setSearchTerm(event.target.value)}
            />
          )}
          {actionButton && (
            <ActionButtonTable onClick={actionButton} label={buttonLabel} />
          )}
          {actionConfig && (
            <Tooltip text="Clique para configurar sistema">
              <Button
                onClick={() => {
                  handleClick("/pagamento/configurar-parametro");
                }}
              >
                <StyledSettingsIcon size={25} />
              </Button>
            </Tooltip>
          )}
          {actionAdd && (
            <Tooltip text="Adicionar um novo estudante">
              <Button
                onClick={() => {
                  handleClick("cadastro");
                }}
              >
                <MdOutlineAdd size={25} />
              </Button>
            </Tooltip>
          )}
          {actionUpdate && (
            <Tooltip text={isDisabledUpdate ?"Aguardando finalizar atualização!" : "Clique para realizar nova atualização!"}>
              <Button disabled={isDisabledUpdate ? true : false}>
                <StyledUpdateIcon size={25} onClick={handleUpdate} />
              </Button>
            </Tooltip>
          )}
        </ContainerSearchAndButton>
        {needTotal && <TotalCount count={filteredData.length} />}
      </Container>
      <Table>
        <TableHead>
          <TableRow>
            {header.map((coluna, index) => (
              <TableHeaderCell key={index}>{coluna}</TableHeaderCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {displayData.map((dado, index) => (
            <TableRow key={index}>
              {colunas.map((coluna) => (
                <TableCell>{dado[coluna]}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {paginated && (
        <Paginacao>
          <button
            onClick={() =>
              setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
            }
            disabled={currentPage === 1}
          >
            Anterior
          </button>
          <span>{`Página ${currentPage} de ${totalPages}`}</span>
          <button
            onClick={() =>
              setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages))
            }
            disabled={currentPage === totalPages}
          >
            Próxima
          </button>
        </Paginacao>
      )}
    </>
  );
};

export default Tabela;
