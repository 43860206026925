import React, { useState } from "react";
import styled from "styled-components";

import { colors } from "../Colors";

const Container = styled.div`
  position: relative;
  display: inline-block;
`;

const Target = styled.div`
  cursor: pointer;
`;

const Box = styled.div`
  position: absolute;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  text-align: center;
  padding: 0.5rem;
  border-radius: 0.25rem;
  white-space: nowrap;
  z-index: 10;
  visibility: visible;
  opacity: 0.9;

  &:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
  }
`;

const Tooltip = ({ text, children }) => {
  const [visible, setVisible] = useState(false);

  const showTooltip = () => setVisible(true);
  const hideTooltip = () => setVisible(false);

  return (
    <Container>
      <Target onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
        {children}
      </Target>
      {visible && (
        <Box style={{ backgroundColor: colors.azulPrimario }}>{text}</Box>
      )}
    </Container>
  );
};

export default Tooltip;
