import React from "react";
import { useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";

import Container from "../../../../components/container/Container";
import CustomButton from "../../../../components/button/Button";
import { GoQuestion } from "react-icons/go";
import { useDataContext } from "../../../../contexts/DataContext";
import Tooltip from "../../../../components/tooltip/Tooltip";
import { Input, Label, LabelIcon } from "../StyledForm";

const CadastroForm = ({
  formData,
  setFormData,
  tituloSelect,
  placeholder,
  titulo,
  label,
  options,
  isEditar = false,
}) => {
  const navigate = useNavigate();
  const { mutationPostProfile, mutationPutProfile } = useDataContext();
  const tituloForm = isEditar ? `Editar ${titulo}` : `Cadastrar ${titulo}`;

  const handleChange = (e) => {
    let name;
    let value;
    try {
      name = e.target.name;
      value = e.target.value;
    } catch {
      value = e.value;
      name = "escola";
    }

    if (name === "escola") {
      setFormData({
        ...formData,
        [name]: { id: value },
      });
    } else {
      const hasFixedLengthPlaceholder = ["cpf"].includes(name);

      if (hasFixedLengthPlaceholder) {
        const maxLength = e.target.placeholder.length;
        const numericValue = value.replace(/\D/g, "");
        let sanitizedValue = DOMPurify.sanitize(numericValue);
        if (sanitizedValue.length <= maxLength) {
          setFormData({
            ...formData,
            [name]: sanitizedValue,
          });
        }
      } else {
        let sanitizedValue = DOMPurify.sanitize(value);
        setFormData({
          ...formData,
          [name]: sanitizedValue,
        });
      }
    }
  };

  const handleSubmit = async () => {
    if (isEditar) {
      try {
        await mutationPutProfile.mutateAsync(formData);
        navigate("/usuarios");
      } catch (error) {
        console.error("Erro ao editar usuário:", error);
      }
    } else {
      try {
        await mutationPostProfile.mutateAsync(formData);
        navigate("/usuarios");
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleSubmitEventDefault = (e) => {
    e.preventDefault();
    handleSubmit();
  };

  return (
    <Container titulo={tituloForm} style={{ marginTop: "10%" }}>
      <form
        onSubmit={(e) => handleSubmitEventDefault(e)}
        style={{ margin: "auto 8px" }}
      >
        <div>
          <Label>
            Nome Completo:<span style={{ color: "red" }}>*</span>
            <Input
              type="text"
              name="nomeCompleto"
              value={formData.nomeCompleto}
              onChange={handleChange}
              required
            />
          </Label>
          <Label>
            Email:<span style={{ color: "red" }}>*</span>
            <Input
              type="text"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </Label>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ flex: 1, marginRight: "20px" }}>
              <LabelIcon>
                <Label>
                  CPF:<span style={{ color: "red" }}>*</span>
                </Label>
              </LabelIcon>
              <Input
                type="text"
                name="cpf"
                placeholder="00000000000"
                value={formData.cpf}
                onChange={handleChange}
                required
              />
            </div>
            <div style={{ flex: 1 }}>
              <LabelIcon>
                <Label htmlFor="matricula">
                  Matricula:<span style={{ color: "red" }}>*</span>
                </Label>
                <Tooltip text="Informe sua matricula">
                  <GoQuestion size={20} />
                </Tooltip>
              </LabelIcon>
              <Input
                type="text"
                name="matricula"
                id="matricula"
                value={formData.matricula}
                disabled={isEditar ? true : false}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div>
            {formData.profile === "ADMIN" ? (
              <>
                <LabelIcon style={{ marginTop: ".3em" }}>
                  <Label htmlFor={tituloSelect}>
                    {label}:<span style={{ color: "red" }}>*</span>
                  </Label>
                  <Tooltip text={placeholder}>
                    <GoQuestion size={20} />
                  </Tooltip>
                </LabelIcon>
                <Input
                  type="text"
                  id={tituloSelect}
                  name={tituloSelect}
                  value={formData.setor}
                  onChange={handleChange}
                  required
                />
              </>
            ) : formData.profile === "AVALIADOR" ? (
              <>
                <LabelIcon style={{ marginTop: ".4em" }}>
                  <Label htmlFor={tituloSelect}>
                    {label}:<span style={{ color: "red" }}>*</span>
                  </Label>
                  <Tooltip text={placeholder}>
                    <GoQuestion size={23} />
                  </Tooltip>
                </LabelIcon>
                <select
                  id={tituloSelect}
                  name={tituloSelect}
                  value={formData.regional || ""}
                  onChange={handleChange}
                  required
                  style={{
                    fontSize: "1em",
                    width: "100%",
                    border: "1px solid transparent",
                    borderRadius: "5px",
                    backgroundColor: "#ebebeb",
                    height: "35px",
                    marginTop: ".2em",
                  }}
                >
                  <option value="">{placeholder}</option>
                  {options.length > 0 ? (
                    options.map((option) => (
                      <option key={option.id} value={option.value}>
                        {option.label}
                      </option>
                    ))
                  ) : (
                    <option value="" disabled>
                      Nenhuma opção disponível
                    </option>
                  )}
                </select>
              </>
            ) : (
              <>
                <LabelIcon style={{ marginTop: ".4em" }}>
                  <Label htmlFor={tituloSelect}>
                    {label}:<span style={{ color: "red" }}>*</span>
                  </Label>
                  <Tooltip text={placeholder}>
                    <GoQuestion size={23} />
                  </Tooltip>
                </LabelIcon>
                <select
                  id={tituloSelect}
                  name={tituloSelect}
                  onChange={handleChange}
                  value={formData.escola?.id || ""}
                  required
                  style={{
                    fontSize: "1em",
                    width: "100%",
                    border: "1px solid transparent",
                    borderRadius: "5px",
                    backgroundColor: "#ebebeb",
                    height: "35px",
                    marginTop: ".2em",
                  }}
                >
                  <option value="">{placeholder}</option>
                  {options.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </>
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            gap: "30px",
            justifyContent: "space-between",
            width: "60%",
            marginLeft: "20%",
            marginTop: "15px",
          }}
        >
          <CustomButton type="submit">
            {isEditar ? "Atualizar" : "Cadastrar"}
          </CustomButton>
          <CustomButton variant="cancel" onClick={handleCancel}>
            Cancelar
          </CustomButton>
        </div>
      </form>
    </Container>
  );
};

export default CadastroForm;
