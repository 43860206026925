import React, { useState } from "react";
import styled from "styled-components";

import { useUserContext } from "../../contexts/UserContext";
import CustomButton from "../button/Button";
import { ButtonsWrapper, Content, Overlay } from "./StyledModal";
import { useDataContext } from "../../contexts/DataContext";

const StyledButton = styled.button`
  border-radius: 5px;
  text-align: center;
  font-size: 20px;
  padding: 10px;
  color: #fff;
  background-color: #00acc1;
  border-color: transparent;
  width: 100%;
  height: 50px;
  font-weight: 700;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 15px;
  transition: background 0.3s ease;

  &:disabled {
    background-color: #b0bec5;
    cursor: not-allowed;
  }
`;

const ModalPagamento = ({ isOpen, onCancel, onProceed }) => {
  const { token } = useUserContext();
  const { mutationPostPagamento } = useDataContext();
  const [isEnviado, setIsEnviado] = useState(null);
  const [isEnviando, setIsEnviando] = useState(false);
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();

  if (!isOpen) return null;

  const fetchPagamentosBolsistas = async () => {
    try {
      setIsEnviando(true);
      await mutationPostPagamento.mutateAsync(token);
      setIsEnviado(true);
    } catch (error) {
      setIsEnviado(false);
      console.error("Erro ao realizar pagamento: ", error);
    } finally {
      setIsEnviando(false);
    }
  };

  let valor = currentMonth === 10 ? 1000 : 100;

  const renderStatusMessage = () => {
    if (isEnviado === null) return null;
    if (isEnviado === true) {
      return <p style={{ color: "green" }}>Pagamento Confirmado.</p>;
    } else {
      return <p style={{ color: "red" }}>Erro ao realizar pagamento.</p>;
    }
  };

  return (
    <Overlay>
      <Content>
        <h2>Confirmar Pagamento:</h2>
        <p className="texto">Valor a ser pago: {valor}</p>
        {isEnviando ? (
          <>
            <div className="spinner"></div>
            <p style={{ color: "orange" }}>Aguarde...</p>
          </>
        ) : (
          renderStatusMessage()
        )}
        <ButtonsWrapper>
          <StyledButton
            onClick={fetchPagamentosBolsistas}
            disabled={isEnviando}
          >
            Pagar
          </StyledButton>
          <CustomButton variant="cancel" onClick={onCancel}>
            Cancelar
          </CustomButton>
        </ButtonsWrapper>
      </Content>
    </Overlay>
  );
};

export default ModalPagamento;
