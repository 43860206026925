import React from 'react';
import styled from 'styled-components';

const NotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  background-color: transparent;
  text-align: center;
  color: #333;
`;

const Title = styled.h1`
  font-size: 4rem;
  margin: 0;
`;

const Subtitle = styled.p`
  font-size: 1.5rem;
  margin: 0.5rem 0;
`;

const NotFoundPage = () => {
  return (
    <NotFoundContainer>
      <Title>404</Title>
      <Subtitle>Página não encontrada</Subtitle>
    </NotFoundContainer>
  );
};

export default NotFoundPage;
