import React, { useState } from "react";
import { Chart } from "react-google-charts";
import styled from "styled-components";

import { useUserContext } from "../../contexts/UserContext";
import { Tabs, Tab } from "../../components/modal/StyledModal";
import { useDataContext } from "../../contexts/DataContext";

const GraficosWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(600px, 1fr));
  gap: 20px;
`;

const Grafico = styled.div`
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
`;

const Dashboard = () => {
  const { user } = useUserContext();
  const { bolsistas, escolas } = useDataContext();
  const [activeTab, setActiveTab] = useState(0);

  const backgroundColor = "transparent";
  var nomeRegional;

  if (user.profile !== "MULTIPROFISSIONAL") {
    switch (user.regional) {
      case "ARAGUAINA":
        nomeRegional = "Araguaína";
        break;
      case "ARAGUATINS":
        nomeRegional = "Araguatins";
        break;
      case "ARRAIAS":
        nomeRegional = "Arraias";
        break;
      case "COLINAS":
        nomeRegional = "Colinas do Tocantins";
        break;
      case "DIANOPOLIS":
        nomeRegional = "Dianópolis";
        break;
      case "GUARAI":
        nomeRegional = "Guaraí";
        break;
      case "GURUPI":
        nomeRegional = "Gurupi";
        break;
      case "MIRACEMA":
        nomeRegional = "Miracema do Tocantins";
        break;
      case "PALMAS":
        nomeRegional = "Palmas";
        break;
      case "PARAISO":
        nomeRegional = "Paraíso do Tocantins";
        break;
      case "PEDROAFONSO":
        nomeRegional = "Pedro Afonso";
        break;
      case "PORTONACIONAL":
        nomeRegional = "Porto Nacional";
        break;
      case "TOCANTINOPOLIS":
        nomeRegional = "Tocantinópolis";
        break;
      default:
        nomeRegional = "Regional não encontrada";
    }
  }

  const widthGraphic = "80%";
  const heightGraphic = "70%";

  const options = {
    title: "Estudantes por Status",
    pieHole: 0.4,
    is3D: false,
    slices: [
      { color: "green" },
      { color: "#20B2AA" },
      { color: "red" },
      { color: "orange" },
    ],
    titleTextStyle: {
      fontSize: 18,
    },
    chartArea: {
      width: widthGraphic,
      height: heightGraphic,
    },
    backgroundColor: backgroundColor,
  };

  const options2 = {
    title: "Estudantes por Regional",
    pieHole: 0.4,
    is3D: false,
    titleTextStyle: {
      fontSize: 18,
    },
    sliceVisibilityThreshold: 0,
    legend: {
      position: "right",
      maxLines: 2,
      textStyle: {
        fontSize: 12,
      },
    },
    chartArea: {
      width: widthGraphic,
      height: heightGraphic,
    },
    backgroundColor: backgroundColor,
  };

  const options3 = {
    title: "Escolas por Regional",
    pieHole: 0.4,
    is3D: false,
    titleTextStyle: {
      fontSize: 18,
    },
    sliceVisibilityThreshold: 0,
    legend: {
      position: "right",
      maxLines: 2,
      textStyle: {
        fontSize: 12,
      },
    },
    chartArea: {
      width: widthGraphic,
      height: heightGraphic,
    },
    backgroundColor: backgroundColor,
  };

  const options4 = {
    title: "Estudantes cadastrados por Escola Total - Regional " + nomeRegional,
    pieHole: 0.4,
    is3D: false,
    titleTextStyle: {
      fontSize: 18,
    },
    sliceVisibilityThreshold: 0,
    legend: {
      position: "right",
      maxLines: 2,
      textStyle: {
        fontSize: 12,
      },
    },
    chartArea: {
      width: widthGraphic,
      height: heightGraphic,
    },
    backgroundColor: backgroundColor,
  };

  const options5 = {
    title: "Estudantes por Série e Ano ",
    pieHole: 0.4,
    is3D: false,
    titleTextStyle: {
      fontSize: 18,
    },
    sliceVisibilityThreshold: 0,
    legend: {
      position: "right",
      maxLines: 2,
      textStyle: {
        fontSize: 12,
      },
    },
    chartArea: {
      width: widthGraphic,
      height: heightGraphic,
    },
    backgroundColor: backgroundColor,
  };

  const bolsistasAtivos = bolsistas.filter(
    (bolsista) =>
      (bolsista.status === "ativo") & (bolsista.ehNovoEstudante === false)
  );
  const totalAtivos = bolsistasAtivos.length;

  const bolsistasnovodAtivos = bolsistas.filter(
    (bolsista) =>
      (bolsista.status === "ativo") & (bolsista.ehNovoEstudante === true)
  );
  const totalnovosAtivos = bolsistasnovodAtivos.length;

  const bolsistasIndeferidos = bolsistas.filter(
    (bolsista) => bolsista.status === "indeferido"
  );
  const totalIndeferido = bolsistasIndeferidos.length;

  const bolsistasPendentes = bolsistas.filter(
    (bolsista) => bolsista.status === "pendente"
  );
  const totalPendente = bolsistasPendentes.length;

  const bolsistasInativos = bolsistas.filter(
    (bolsista) => bolsista.status === "inativo"
  );
  const totalinativo = bolsistasInativos.length;

  const nonoano = bolsistas.filter(
    (bolsista) => bolsista.serieDescricao === "9º ANO"
  );
  const totalNono = nonoano.length;

  const primoserie = bolsistas.filter(
    (bolsista) => bolsista.serieDescricao === "1ª SÉRIE"
  );
  const totalPrimo = primoserie.length;

  const segunserie = bolsistas.filter(
    (bolsista) => bolsista.serieDescricao === "2ª SÉRIE"
  );
  const totalSegun = segunserie.length;

  const terceiraserie = bolsistas.filter(
    (bolsista) => bolsista.serieDescricao === "3ª SÉRIE"
  );
  const totalTerce = terceiraserie.length;

  const dadosStatus = [
    ["Status", "Quantidade"],
    ["Homologados", totalAtivos],
    ["Novos Homologados", totalnovosAtivos],
    ["Indeferidos", totalIndeferido],
    ["Cadastrados", totalPendente],
    ["Inativos", totalinativo],
  ];

  const dadosSerie = [
    ["Série/Ano", "Quantidade"],
    ["9° Ano - EF", totalNono],
    ["1° Série - EM", totalPrimo],
    ["2° Série - EM", totalSegun],
    ["3° Série - EM", totalTerce],
  ];

  const EstudantesPorRegional = (regional) => {
    return bolsistas.filter((b) => b.escola?.regional === regional).length;
  };

  const estudantePorRegional = [
    ["Regional", "Total De Estudante"],
    ["Araguaína", EstudantesPorRegional("ARAGUAINA") || 0],
    ["Araguatins", EstudantesPorRegional("ARAGUATINS") || 0],
    ["Arraias", EstudantesPorRegional("ARRAIS") || 0],
    ["Colinas do Tocantins", EstudantesPorRegional("COLINAS") || 0],
    ["Dianópolis", EstudantesPorRegional("DIANOPOLIS") || 0],
    ["Guaraí", EstudantesPorRegional("GUARAI") || 0],
    ["Gurupi", EstudantesPorRegional("GURUPI") || 0],
    ["Miracena do Tocantins", EstudantesPorRegional("MIRACEMA") || 0],
    ["Palmas", EstudantesPorRegional("PALMAS") || 0],
    ["Paraíso do Tocantins", EstudantesPorRegional("PARAISO") || 0],
    ["Pedro Afonso", EstudantesPorRegional("PEDROAFONSO") || 0],
    ["Porto Nacional", EstudantesPorRegional("PORTONACIONAL") || 0],
    ["Tocantinópolis", EstudantesPorRegional("TOCANTINOPOLIS") || 0],
  ];

  let escolaPorRegional = [];
  let escolasDaRegional = [];

  if (escolas !== "UNAUTHORIZED") {
    escolaPorRegional = [
      ["Regional", "Total De Escolas"],
      [
        "Araguaína",
        escolas.filter((item) => item.regional === "ARAGUAINA").length,
      ],
      [
        "Araguatins",
        escolas.filter((item) => item.regional === "ARAGUATINS").length,
      ],
      ["Arraias", escolas.filter((item) => item.regional === "ARRAIAS").length],
      [
        "Colinas do Tocantins",
        escolas.filter((item) => item.regional === "COLINAS").length,
      ],
      [
        "Dianópolis",
        escolas.filter((item) => item.regional === "DIANOPOLIS").length,
      ],
      ["Guaraí", escolas.filter((item) => item.regional === "GUARAI").length],
      ["Gurupi", escolas.filter((item) => item.regional === "GURUPI").length],
      [
        "Miracena do Tocantins",
        escolas.filter((item) => item.regional === "MIRACEMA").length,
      ],
      ["Palmas", escolas.filter((item) => item.regional === "PALMAS").length],
      [
        "Paraíso do Tocantins",
        escolas.filter((item) => item.regional === "PARAISO").length,
      ],
      [
        "Pedro Afonso",
        escolas.filter((item) => item.regional === "PEDROAFONSO").length,
      ],
      [
        "Porto Nacional",
        escolas.filter((item) => item.regional === "PORTONACIONAL").length,
      ],
      [
        "Tocantinópolis",
        escolas.filter((item) => item.regional === "TOCANTINOPOLIS").length,
      ],
    ];

    escolasDaRegional = [
      ["Escola", "Total de Alunos"],
      ...escolas.map((escola) => [escola.name, escola.totalBolsistas]),
    ];
  }

  const widthChart = "600px";
  const heightChart = "400px";
  return (
    <>
      <Tabs>
        <Tab $active={activeTab === 0} onClick={() => setActiveTab(0)}>
          TODOS
        </Tab>
        <Tab $active={activeTab === 1} onClick={() => setActiveTab(1)}>
          ESCOLAS
        </Tab>
        <Tab $active={activeTab === 2} onClick={() => setActiveTab(2)}>
          ESTUDANTES
        </Tab>
      </Tabs>
      {user.profile === "ADMIN" && (
        <>
          <GraficosWrapper>
            {activeTab !== 1 && (
              <>
                <Grafico>
                  <Chart
                    width={widthChart}
                    height={heightChart}
                    chartType="PieChart"
                    data={dadosStatus}
                    options={options}
                  />
                </Grafico>
                <Grafico>
                  <Chart
                    width={widthChart}
                    height={heightChart}
                    chartType="PieChart"
                    data={dadosSerie}
                    options={options5}
                  />
                </Grafico>
                <Grafico>
                  <Chart
                    width={widthChart}
                    height={heightChart}
                    chartType="PieChart"
                    data={estudantePorRegional}
                    options={options2}
                  />
                </Grafico>
              </>
            )}
            {activeTab !== 2 && (
              <Grafico>
                <Chart
                  width={widthChart}
                  height={heightChart}
                  chartType="PieChart"
                  data={escolaPorRegional}
                  options={options3}
                />
              </Grafico>
            )}
          </GraficosWrapper>
        </>
      )}
      {user.profile === "AVALIADOR" && (
        <>
          {activeTab !== 1 && (
            <>
              <Grafico>
                <Chart
                  width={widthChart}
                  height={heightChart}
                  chartType="PieChart"
                  data={dadosStatus}
                  options={options}
                />
              </Grafico>
              <Grafico>
                <Chart
                  width={widthChart}
                  height={heightChart}
                  chartType="PieChart"
                  data={dadosSerie}
                  options={options5}
                />
              </Grafico>
            </>
          )}
          {activeTab !== 2 && (
            <Grafico>
              <Chart
                width={widthChart}
                height={heightChart}
                chartType="PieChart"
                data={escolasDaRegional}
                options={options4}
              />
            </Grafico>
          )}
        </>
      )}
      {user.profile === "MULTIPROFISSIONAL" && (
        <>
          <Grafico>
            <Chart
              width={widthChart}
              height={heightChart}
              chartType="PieChart"
              data={dadosStatus}
              options={options}
            />
          </Grafico>
          <Grafico>
            <Chart
              width={widthChart}
              height={heightChart}
              chartType="PieChart"
              data={dadosSerie}
              options={options5}
            />
          </Grafico>
          <Grafico>
            <Chart
              width={widthChart}
              height={heightChart}
              chartType="PieChart"
              data={estudantePorRegional}
              options={options2}
            />
          </Grafico>
        </>
      )}
    </>
  );
};

export default Dashboard;
