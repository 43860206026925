import React from 'react';
import styled from 'styled-components';

const Input = styled.input`
  width: 200px;
  height: 40px;
  border: 1px solid #0062bc;
  border-radius: 15px;
  font-size: 16px;
  text-align: justify;
  background-color: #f3f3f3;
  padding-left: 10px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.15);

  &:focus {
    outline: 2px solid #0062bc;
    background-color: #fff;
  }
`;

const SearchBar = ({ onChange }) => (
  <Input
    type="text"
    placeholder="Pesquisar..."
    onChange={onChange}
  />
);

export default SearchBar;
