import React from "react";
import "./card.css";
import CustomCard from "./CustomCard";

function CardGroup({ menuItems }) {
  return (
    <div className="menu-container">
      {menuItems &&
        menuItems.map((item) => {
          return (
           <CustomCard key={item.id} item={item} type={item.type} variant={item.variant} style={{marginTop: '100px'}} icon={item.icon}/>
          );
        })}
    </div>
  );
}

export default CardGroup;
