const permissionMap = {
  ADMIN: [
    "/home",
    "/dashboard",
    "/escolas",
    "/estudantes",
    "/pagamento",
    "/cadastro",
    "/cadastrados",
    "/planilhas",
    "/usuarios",
    "/sobre",
    "/suporte",
    "/notificacao",
    "/atualizacoes",
  ],
  SECRETARIO: [
    "/home",
    "/estudantes",
    "/ativos",
    "/novos-homologados",
    "/cadastrados",
    "/indeferidos",
    "/cadastro/estudante",
    "/cadastro/estudante?editar=true",
    "/sobre",
    "/suporte",
    "/notificacao",
  ],
  AVALIADOR: [
    "/home",
    "/dashboard",
    "/escolas",
    "/estudantes",
    "/ativos",
    "/novos-homologados",
    "/cadastrados",
    "/indeferidos",
    "/cadastro/escola",
    "/planilhas",
    "/sobre",
    "/suporte",
    "/notificacao",
  ],
  MULTIPROFISSIONAL: [
    "/home",
    "/dashboard",
    "/estudantes",
    "/ativos",
    "/novos-homologados",
    "/cadastrados",
    "/indeferidos",
    "/cadastro/estudante?editar=true",
    "/planilhas",
    "/relatorios",
    "/sobre",
    "/suporte",
    "/notificacao",
  ],
};

export default permissionMap;
