import React from "react";
import CustomButton from "../button/Button";
import { ButtonsWrapper, Content, Overlay, Title } from "./StyledModal";

const Modal = ({
  isOpen,
  text,
  onCancel,
  onProceed,
  textoOk = "Permanecer",
  textoCancelar = "Sair",
}) => {
  if (!isOpen) return null;

  return (
    <Overlay>
      <Content>
        <Title>{text}</Title>
        <ButtonsWrapper>
          <CustomButton onClick={onProceed}>{textoOk}</CustomButton>
          <CustomButton variant="cancel" onClick={onCancel}>
            {textoCancelar}
          </CustomButton>
        </ButtonsWrapper>
      </Content>
    </Overlay>
  );
};

export default Modal;
