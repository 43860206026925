export const colors = {
  azulPrimario: "#0062BC",
  azulSecundario: "#043C70",
  azulTerciario: "#04294B",
  azulButton: "#00A3FF",
  azulMarinhoPrimario: "#79c1e0",
  verdePrimario: "#3CB371",
  vermelhoPrimario: "#F17B7B",
  vermelhoSecundario: "#FF0000",
  amareloPrimario: "#04B4AE",
  preto: "#000000",
  brancoPrimario: "#FFFFFF",
  brancoSecundario: "#ededed",
};
