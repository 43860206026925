import React, { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import { useUserContext } from "../../../contexts/UserContext";
import MensagemForm from "./MensagemForm";
import { Notify, ToastType } from "../../../components/notify/notify";

const Mensagem = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const previousData = location.state?.previousData || null;
  const isEditar = previousData !== null;

  const { user } = useUserContext();
  // se user não for administrador caso seja edição, ele não acessa a página
  const isNotAllowed =
    !user.profile === "ADMIN";

  const [formData, setFormData] = useState({
    titulo: "",
    corpo: "",
    destinaAvaliador: true,
    destinaSecretario: true,
    destinaMultiprofissional: true,
    ehlido: false,
    remetente: user,
  });

  useEffect(() => {
    if (previousData) {
      setFormData(previousData);
    }

    if (isNotAllowed) {
      Notify({
        message: `Você não tem autorização para essa página!`,
        type: ToastType.Error,
      });
      navigate(-1);
    }
  }, [previousData, navigate, isNotAllowed]);

  return (
    user && (
      <div>
        {!isNotAllowed && (
          <MensagemForm
            formData={formData}
            setFormData={setFormData}
            isEditar={isEditar}
          />
        )}
      </div>
    )
  );
};

export default Mensagem;
