import React from "react";
import styled from "styled-components";

const Button = styled.button`
  border-radius: 15px;
  text-align: center;
  font-size: 20px;
  padding: 10px;
  color: #fff;
  background-color: #0062bc;
  border: none;
  width: 250px;
  height: 45px;
  font-weight: 700;
  cursor: pointer;
  transition: background 0.3s ease;

  &:hover {
    background-color: #0070d1;
  }

  &:disabled {
    background-color: #b0bec5;
    cursor: not-allowed;
  }
`;

const ActionButton = ({ onClick, label, disabled }) => (
  <Button onClick={onClick} disabled={disabled}>
    {label}
  </Button>
);

export default ActionButton;
