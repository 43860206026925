export const formatCPF = (cpf) => {
  const cleanCPF = cpf.replace(/\D/g, "");
  return cleanCPF.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
};

export const formatDate = (dateString) => {
  if (dateString === null) {
    return dateString;
  }
  const [year, month, day] = dateString.split("-");
  return `${day}/${month}/${year}`;
};

export const formatName = (name) => {
  const words = name.toLowerCase().split(" ");
  const formatedName = words.map((word, index) => {
    const lowerCaseWords = ["de", "da", "do", "das", "dos"];
    if (lowerCaseWords.includes(word) && index !== 0) {
      return word;
    }
    return word.charAt(0).toUpperCase() + word.slice(1);
  });
  return formatedName.join(" ");
};

export const formatNumbersOnly = (value) => {
  return value.replace(/\D/g, "");
};

export const formatNumberWithDot = (value) => {
  return value.toLocaleString('pt-BR');
}
