import React from "react";
import { Navigate, useNavigate, useLocation } from "react-router-dom";

import { useUserContext } from "../../contexts/UserContext";
import permissionMap from "./permissionMap";

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const { token, user, setToken } = useUserContext();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isEditMode = searchParams.get("editar") === "true";

  const tryToken = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/auth/validaToken`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!response.ok) {
      setToken(null);
      navigate("/login");
    }
  };

  if (!token) {
    return <Navigate to="/login" replace />;
  } else {
    tryToken();
  }

  const allowedRoutes = permissionMap[user.profile] || [];
  const pathnameWithQuery = isEditMode
    ? `${location.pathname}?editar=true`
    : location.pathname;
  const isAllowed = allowedRoutes.some((route) =>
    pathnameWithQuery.toLowerCase().startsWith(route)
  );

  if (!isAllowed) {
    return <Navigate to="/home" replace />;
  }

  return children;
};

export default ProtectedRoute;
