import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import DOMPurify from "dompurify";

import bancos from "../../../../Assets/bancos.json";
import Container from "../../../../components/container/Container";
import CustomButton from "../../../../components/button/Button";
import { Input, Label, FormGroup, ButtonWrapper, FieldSpan } from "../StyledForm";

import { useUserContext } from "../../../../contexts/UserContext";
import { Notify, ToastType } from "../../../../components/notify/notify";
import { useDataContext } from "../../../../contexts/DataContext";

const selectOptions = [
  { id: 9, value: "9° ANO", label: "9º Ano" },
  { id: 1, value: "1° SÉRIE", label: "1º Série (Ensino Médio)" },
  { id: 2, value: "2° SÉRIE", label: "2º Série (Ensino Médio)" },
  { id: 3, value: "3° SÉRIE", label: "3º Série (Ensino Médio)" },
];

const EstudanteForm = ({
  formData,
  setFormData,
  isEditar = false,
  isAtivo = false,
}) => {
  const navigate = useNavigate();
  const { token } = useUserContext();
  const { mutationPostBolsista, mutationPutBolsista } = useDataContext();
  const [customBankName, setCustomBankName] = useState("");

  const tituloForm = isEditar ? `Editar` : `Cadastrar`;
  const [formCpf, setFormCpf] = useState({ cpf: "" });

  const [loading, setLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(isEditar ? true : false);
  const formatAccount = (banco, agencia, conta) => {
    switch (banco) {
      case "001":
      case "003":
      case "389":
        return `Agência ${agencia}, Conta Corrente ${conta}`;
      case "104":
        return `Agência ${agencia}, Operação 001, Conta Corrente ${conta}`;
      default:
        return `Agência ${agencia}, Conta Corrente ${conta.slice(
          0,
          conta.length - 1
        )}-${conta.slice(-1)}`;
    }
  };

  const formattedBanks = bancos
    .filter((banco) => banco.fullName && banco.fullName.trim() !== "")
    .sort((a, b) => a.fullName.localeCompare(b.fullName))
    .map((banco) => ({
      value: banco.ispb,
      label: banco.fullName,
    }));

  const handleChangeCpf = (e) => {
    const { name, value } = e.target;

    const hasFixedLengthPlaceholder = ["cpf"].includes(name);

    if (hasFixedLengthPlaceholder) {
      const maxLength = e.target.placeholder.length;
      const numericValue = value.replace(/\D/g, "");
      let sanitizedValue = DOMPurify.sanitize(numericValue);
      if (numericValue.length <= maxLength) {
        setFormCpf({ ...formCpf, [name]: sanitizedValue });
      }
    } else {
      let sanitizedValue = DOMPurify.sanitize(value);
      setFormCpf({ ...formCpf, [name]: sanitizedValue });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const isContaField = name === "conta";
    const isAgenciaField = name === "agencia";
    const isNisField = name === "nis";

    let sanitizedValue = DOMPurify.sanitize(value);

    if ((isContaField || isAgenciaField) && formData.banco !== "") {
      const fieldMaxLength = isContaField ? "conta" : "agencia";
      const bancoSelecionado = bancos.find(
        (banco) => banco.code === formData.banco
      );

      const maxDigits =
        fieldMaxLength === "conta" &&
        bancoSelecionado &&
        bancoSelecionado.code !== "104"
          ? 12
          : 20;
      if (sanitizedValue.length <= maxDigits) {
        setFormData({
          ...formData,
          [name]: sanitizedValue,
        });
      }
    } else if (isNisField) {
      setFormData({
        ...formData,
        [name]: Number(sanitizedValue),
      });
    } else {
      setFormData({
        ...formData,
        [name]: sanitizedValue,
      });
    }
  };

  const mapSerieId = (id) => {
    const mapping = {
      10: 1,
      11: 2,
      12: 3,
    };
    return mapping[id] || id;
  };

  const handleChangeSelect = (e) => {
    const { name, value } = e.target;
    if (name === 'serieId'){
      setFormData((prevState) => ({
        ...prevState,
        [name]: mapSerieId(Number(value)),
      }));
    }else{
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const buscarAPI = async (cpf) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/bolsista/fillForm/${cpf}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // NOTE - por conta de problemas no SGE, a resposta será sempre ok,
      // pois caso o aluno não esteja no SGE por conta de algum erro, tem
      // que ser ainda possível cadastrar ele
      if (!response.ok) {
        Notify({
          message: `Erro ao buscar estudante na API. ${response.statusText}`,
          type: ToastType.Error,
        });
        throw new Error("Erro ao buscar estudante na API");
      }

      const data = await response.json();
      return data;
    } catch (error) {
      Notify({
        message: `Erro ao buscar estudante na API. ${error.message}`,
        type: ToastType.Error,
      });
      console.error("Erro ao buscar estudante na API", error);
      return null;
    }
  };

  const handleSubmitCpf = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const estudanteData = await buscarAPI(formCpf.cpf);
      if (estudanteData !== "NOT_FOUND") {
        setDataLoaded(true);
        setFormData((prevData) => ({
          ...prevData,
          nome: estudanteData.nome,
          cpf: estudanteData.cpf
            ? DOMPurify.sanitize(estudanteData.cpf.replace(/\D/g, ""))
            : "",
          serieId: estudanteData.serieId,
          serieDescricao: estudanteData.serieDescricao,
          nis: estudanteData.nis,
          matricula: estudanteData.matricula,
          dataNascimento: estudanteData.dataNascimento
            ? estudanteData.dataNascimento.split("T")[0]
            : "",
        }));
        Notify({ message: `Estudante encontrado`, type: ToastType.Success });
      } else {
        Notify({
          message: `Estudante não encontrado no SGE.`,
          type: ToastType.Error,
        });
      }
    } catch (error) {
      Notify({
        message: `Erro ao buscar estudante! Tente mais tarde.`,
        type: ToastType.Error,
      });
      console.error("Erro ao buscar estudante", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isEditar) {
      try {
        await mutationPutBolsista.mutateAsync(formData);
      } catch (error) {
        console.error("Erro ao editar bolsista:", error);
      }
    } else {
      try {
        await mutationPostBolsista.mutateAsync(formData);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const handleClickField = () => {
    // FIXME - corrigir isso aqui
    Notify({
      message: "Não é possível alterar dados cadastrais oriundos do SGE.",
      type: ToastType.Error,
    });
  };

  return (
    <Container titulo={`${tituloForm} estudante`} style={{ marginTop: "10%" }}>
      {!isEditar && (
        <form
          onSubmit={handleSubmitCpf}
          style={{ padding: dataLoaded ? "0 0" : "0 10px 10px" }}
        >
          <Label>
            Digite o CPF do estudante:<span style={{ color: "red" }}>*</span>
          </Label>
          <FormGroup>
            <Input
              type="text"
              name="cpf"
              placeholder="00000000000"
              value={formCpf.cpf}
              onChange={handleChangeCpf}
              required
            />
            <ButtonWrapper>
              <CustomButton
                type="submit"
                style={{ marginTop: "0px", height: "38px" }}
              >
                <FaSearch />
              </CustomButton>
            </ButtonWrapper>
          </FormGroup>
        </form>
      )}
      {loading && (
        <div className="loading-popup">Buscando estudante no SGE...</div>
      )}
      {dataLoaded && (
        <form onSubmit={handleSubmit}>
          {!isAtivo && <br />}
          <br />
          <Label>
            Nome Completo:<span style={{ color: "red" }}>*</span>
            <div onClick={handleClickField}>
              <Input
                type="text"
                name="nome"
                value={formData.nome}
                onChange={handleChange}
                disabled
                required
              />
            </div>
          </Label>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ flex: 1, marginRight: "20px" }}>
              <Label>
                Data de Nascimento:<span style={{ color: "red" }}>*</span>
                <Input
                  type="date"
                  name="dataNascimento"
                  value={formData.dataNascimento}
                  onChange={handleChange}
                  disabled
                  onClick={handleClickField}
                  required
                />
              </Label>
            </div>
            <div style={{ flex: 1 }}>
              <Label>
                NIS:<span style={{ color: "red" }}>*</span>
                <Input
                  type="text"
                  name="nis"
                  placeholder="00000000000"
                  value={formData.nis}
                  onChange={handleChange}
                  required
                />
              </Label>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ flex: 1, marginRight: "20px" }}>
              <Label>
                ID de matrícula:<span style={{ color: "red" }}>*</span>
                <Input
                  type="text"
                  name="matricula"
                  value={formData.matricula}
                  onChange={handleChange}
                  disabled
                  required
                />
              </Label>
            </div>
            <div style={{ flex: 1 }}>
              <Label>
                Série/Ano:<span style={{ color: "red" }}>*</span>
                <select
                  id="serieId"
                  name="serieId"
                  onChange={handleChangeSelect}
                  value={mapSerieId(formData.serieId) || ""}
                  disabled
                  required
                  style={{
                    fontSize: "1em",
                    width: "100%",
                    border: "1px solid transparent",
                    borderRadius: "5px",
                    backgroundColor: "#ebebeb",
                    height: "35px",
                    marginTop: ".2em",
                  }}
                >
                  <option value="">Selecione uma Série/Ano</option>
                  {selectOptions.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </Label>
            </div>
          </div>
          <FieldSpan>Dados Bancários</FieldSpan>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ flex: 1, marginTop: "10px", marginRight: "20px" }}>
              <Label>
                Banco:<span style={{ color: "red" }}>*</span>
                <select
                  id="banco"
                  name="banco"
                  onChange={handleChangeSelect}
                  value={formData.banco  || ""}
                  required
                  style={{
                    fontSize: "1em",
                    width: "100%",
                    border: "1px solid transparent",
                    borderRadius: "5px",
                    backgroundColor: "#ebebeb",
                    height: "35px",
                    marginTop: "0.2em",
                  }}
                >
                  <option value="">Selecione o banco</option>
                  {formattedBanks.length > 0 ? (
                    formattedBanks.map((option, index) => (
                      <option key={`key-${option.value}-${index}`} value={option.label}>
                        {option.label}
                      </option>
                    ))
                  ) : (
                    <option value="" disabled>
                      Nenhuma opção disponível
                    </option>
                  )}
                </select>
                {formData.banco === "outros" && (
                  <Input
                    type="text"
                    name="banco"
                    value={customBankName}
                    onChange={(e) => setCustomBankName(e.target.value)}
                  />
                )}
              </Label>
            </div>
            <div style={{ flex: 1, marginRight: "20px" }}>
              <Label>
                Agência:<span style={{ color: "red" }}>*</span>
                <Input
                  type="text"
                  name="agencia"
                  value={formData.agencia}
                  onChange={handleChange}
                  required
                />
              </Label>
            </div>
            <div style={{ flex: 1 }}>
              <Label>
                Conta:<span style={{ color: "red" }}>*</span>
                <Input
                  type="text"
                  name="conta"
                  value={formData.conta}
                  onChange={handleChange}
                  required
                />
              </Label>
            </div>
          </div>
          <Label>
            Conta Formatada:
            <Input
              type="text"
              value={formatAccount(
                formData.banco,
                formData.agencia,
                formData.conta
              )}
              readOnly
            />
          </Label>
          <div
            style={{
              display: "flex",
              gap: "30px",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <CustomButton type="submit">Salvar</CustomButton>
            <CustomButton variant="cancel" onClick={handleCancel}>
              Cancelar
            </CustomButton>
          </div>
        </form>
      )}
    </Container>
  );
};

export default EstudanteForm;
