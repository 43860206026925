import React from 'react'

import { useDataContext } from "../../contexts/DataContext";
import { useNavigate } from "react-router-dom";
import CardGroup from '../../components/card/CardGroup';
import { FaUserEdit } from "react-icons/fa";
import { FaUsers } from "react-icons/fa";
import { FaUsersCog } from "react-icons/fa";
import { FaUserCheck } from "react-icons/fa";
import { FaSchool } from "react-icons/fa";


const Cadastros = () => {
  const { profiles, totalEscolas } = useDataContext();
  const navigate = useNavigate();

  const Sec = profiles.filter((perfil) => perfil.profile === "SECRETARIO");
  const totalSec = Sec.length;
  const Admin = profiles.filter((perfil) => perfil.profile === "ADMIN");
  const totalAdmin = Admin.length;
  const Mult = profiles.filter(
    (perfil) => perfil.profile === "MULTIPROFISSIONAL",
  );
  const totalMult = Mult.length;
  const Ava = profiles.filter((perfil) => perfil.profile === "AVALIADOR");
  const totalAva = Ava.length;

  const handleClick = (path) => {
    navigate(path);
  };

  const itens = [
    {
      id: 0,
      title: "Administrador",
      variant: "blue",
      type: "titleIconValue",
      value: totalAdmin,
      icon: FaUsersCog,
      buttonAction: () => {
        handleClick("/cadastro/administrador");
      },
    },
    {
      id: 1,
      title: "Avaliador(SRE)",
      variant: "blue",
      type: "titleIconValue",
      value: totalAva,
      icon: FaUserCheck,
      buttonAction: () => {
        handleClick("/cadastro/avaliador-sre");
      },
    },
    {
      id: 2,
      title: "Escola",
      variant: "blue",
      type: "titleIconValue",
      value: totalEscolas,
      icon: FaSchool,
      buttonAction: () => {
        handleClick("/cadastro/escola");
      },
    },
    {
      id: 3,
      title: "Multiprofissional",
      variant: "blue",
      type: "titleIconValue",
      value: totalMult,
      icon: FaUsers,
      buttonAction: () => {
        handleClick("/cadastro/multiprofissional");
      },
    },
    {
      id: 4,
      title: "Secretário",
      variant: "blue",
      type: "titleIconValue",
      value: totalSec,
      icon: FaUserEdit,
      buttonAction: () => {
        handleClick("/cadastro/secretario");
      },
    },
  ];
  return (
    <CardGroup menuItems={itens}/>
  )
}

export default Cadastros
