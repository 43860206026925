import styled from "styled-components";

export const LabelIcon = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Label = styled.label`
  display: inline-block;
  width: 100%;
  text-align: left;
  margin-bottom: 0.5em;
`;

export const Input = styled.input`
  font-size: 1.4em;
  width: 100%;
  border: 1px solid transparent;
  border-radius: 5px;
  background-color: #ebebeb;
  margin-top: 0.2em;
  padding: 0;
  height: 35px;
`;

export const LabelCheckbox = styled(Label)`
  font-size: 1.3em;
`;

export const FormGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const ButtonWrapper = styled.div`
 display: flex;
  justify-content: space-end;
  width: 5%;
  height: 35px;
`;

export const FieldSpan = styled.span`
  font-size: 1.1em;
  color: #888;
  margin-bottom: 4px;
`;
