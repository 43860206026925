import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import HomeAdmin from "./HomeAdmin";
import HomeSecretario from "./HomeSecretario";
import HomeMulti from "./HomeMulti";
import HomeAvaliador from "./HomeAvaliador";
import { useUserContext } from "../../contexts/UserContext";
import { useDataContext } from "../../contexts/DataContext";

const Home = () => {
  const { user, token, setToken } = useUserContext();
  const { bolsistas, totalBolsistas, totalEscolas } = useDataContext();
  const navigate = useNavigate();

  useEffect(() => {
    const tryToken = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/auth/validaToken`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        setToken(null);
        navigate("/login");
      }
    };
    if (!token) {
      localStorage.clear();
      navigate("/login");
    } else {
      tryToken();
    }
  }, [token, navigate, setToken]);

  const calcularTotais = (bolsistas) => {
    const bolsistasAtivos = Array.isArray(bolsistas)
      ? bolsistas.filter(
          (bolsista) =>
            (bolsista.status === "ativo") & !bolsista.ehNovoEstudante
        )
      : [];
    const totalAtivos = bolsistasAtivos.length;
    const bolsistaNovosAtivos = Array.isArray(bolsistas)
      ? bolsistas.filter(
          (bolsista) => (bolsista.status === "ativo") & bolsista.ehNovoEstudante
        )
      : [];
    const totalNovosAtivos = bolsistaNovosAtivos.length;
    const bolsistasIndeferidos = Array.isArray(bolsistas)
      ? bolsistas.filter((bolsista) => bolsista.status === "indeferido")
      : [];
    const totalIndeferido = bolsistasIndeferidos.length;
    const bolsistasPendentes = Array.isArray(bolsistas)
      ? bolsistas.filter((bolsista) => bolsista.status === "pendente")
      : [];
    const totalPendente = bolsistasPendentes.length;

    return {
      totalAtivos,
      totalNovosAtivos,
      totalIndeferido,
      totalPendente,
    };
  };

  const { totalAtivos, totalNovosAtivos, totalIndeferido, totalPendente } =
    calcularTotais(bolsistas);

  const renderHome = () => {
    switch (user.profile) {
      case "ADMIN":
        return (
          <HomeAdmin
            totalEscolas={totalEscolas}
            totalBolsistas={totalBolsistas}
          />
        );
      case "SECRETARIO":
        return (
          <HomeSecretario
            totalAtivos={totalAtivos}
            totalNovosAtivos={totalNovosAtivos}
            totalIndeferido={totalIndeferido}
            totalPendente={totalPendente}
            totalBolsistas={totalBolsistas}
          />
        );
      case "MULTIPROFISSIONAL":
        return (
          <HomeMulti
            totalAtivos={totalAtivos}
            totalNovosAtivos={totalNovosAtivos}
            totalIndeferido={totalIndeferido}
            totalPendente={totalPendente}
            totalBolsistas={totalBolsistas}
          />
        );
      case "AVALIADOR":
        return (
          <HomeAvaliador
            totalAtivos={totalAtivos}
            totalNovosAtivos={totalNovosAtivos}
            totalIndeferido={totalIndeferido}
            totalPendente={totalPendente}
            totalEscolas={totalEscolas}
            totalBolsistas={totalBolsistas}
          />
        );
      default:
        return <HomeAdmin />;
    }
  };

  return renderHome();
};

export default Home;
