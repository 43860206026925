import * as ExcelJS from "exceljs";
import {
  genereateBasePlanilha,
  regularColunas,
  bordaArea,
} from "../uteis/Generate";

const DownloadExcelBolsistas = async (bolsistas, fileName) => {
  const modifiedBolsista = await bolsistas.map((bolsista) => ({
    ESCOLA: bolsista.escola.name.trimEnd(),
    MUNICIPIO: bolsista.escola.municipio.trimEnd(),
    REGIONAL: bolsista.escola.regional.trimEnd(),
    NOME: bolsista.nome,
    CPF: bolsista.cpf.trimEnd(),
    SERIE: bolsista.serieDescricao.trimEnd(),
    NIS: bolsista.nis,
    MATRICULA: bolsista.matricula.trimEnd(),
    BANCO: bolsista.banco.trimEnd(),
    AGENCIA: bolsista.agencia.trimEnd(),
    CONTA: bolsista.conta.trimEnd(),
    NOTAS: bolsista.notasMaioresQue6 === true ? "APTO" : "INAPTO",
    FREQUENCIA: bolsista.frequenciaPercentual > 70 ? "APTO" : "INAPTO",
    STATUS: bolsista.status,
    OBSERVAÇÃO: bolsista.observacaoUpdate,
  })).sort((a, b) => a.REGIONAL.localeCompare(b.REGIONAL));

  const wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet("Bolsistas");
  const titulo =
    "Planilha de Registro de Bolsistas no Sistema de Gerencias de Bolsas - SGB";
  const subtitulo = "Bolsistas";
  genereateBasePlanilha(modifiedBolsista, ws, wb, titulo, subtitulo);

  let regionaisModifiedBolsista = modifiedBolsista.reduce((acc, bolsista) => {
    // Verifica se já existe um array para a regional no acumulador
    if (!acc[bolsista.REGIONAL]) {
      acc[bolsista.REGIONAL] = [];
    }
    // Adiciona o bolsista ao array correspondente à sua regional
    acc[bolsista.REGIONAL].push(bolsista);
    return acc;
  }, {});
  let linha = 11
  for (const regional in regionaisModifiedBolsista) {
    const bolsistas = regionaisModifiedBolsista[regional];
    const colunas = Object.keys(bolsistas[0]);
    ws.mergeCells(linha, 1, linha, colunas.length)
    ws.getCell('A'+linha).value = bolsistas[0].REGIONAL;
    ws.getCell('A'+linha).alignment = { vertical: 'middle', horizontal: 'center' };
    ws.getRow(linha).height = 40;
    ws.getCell('A'+linha).font = { bold: true, size: 11};
    ws.getCell('A'+linha).fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: '2F75B5' } // Azul
    };
    // ws.getCell('A9').border = {
    //     top: { style: 'medium' },
    //     bottom: { style: 'medium' },
    //     left: { style: 'thin' },
    //     right: { style: 'thin' }
    // };
    // ws.mergeCells(10, 1, 10, colunas.length)
    // Adicionar cabeçalho
    const headers = Object.keys(bolsistas[0]);
    const tb = ws.addRow(headers);

    tb.eachCell((cell, colNumber) => {
      cell.font = { bold: true };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "5080AB" }, // Azul
      };
      cell.alignment = { vertical: "middle", horizontal: "center" };
    });

    // Adicionar os dados
    linha += bolsistas.length + 2;
    bolsistas.forEach((row) => {
      ws.addRow(Object.values(row));
    });

  }

  // Ajusta a largura das colunas
  regularColunas(ws);

  // Adiciona Bordas
  bordaArea(modifiedBolsista, ws);

  // Salvar o workbook como XLSX
  var FileSaver = require("file-saver");
  const doc = await wb.xlsx.writeBuffer();
  FileSaver.saveAs(new Blob([doc]), fileName);
};

export default DownloadExcelBolsistas;
