import React, { useEffect, useState } from "react";

import { useUserContext } from "../../../../contexts/UserContext";
import CadastroForm from "./CadastroForm";
import { useLocation } from "react-router-dom";

const Avaliador = () => {
  const { user } = useUserContext();
  const location = useLocation();
  const previousData = location.state?.previousData || null;
  const isEditar = previousData !== null;

  const [formData, setFormData] = useState({
    nomeCompleto: "",
    email: "",
    cpf: "",
    matricula: "",
    regional: "",
    escola: null,
    setor: null,
    profile: "AVALIADOR",
  });

  const options = [
    { id: 0, value: "ARAGUAINA", label: "Araguaína" },
    { id: 1, value: "ARAGUATINS", label: "Araguatins" },
    { id: 2, value: "ARRAIAS", label: "Arraias" },
    { id: 3, value: "COLINAS", label: "Colinas do Tocantins" },
    { id: 4, value: "DIANOPOLIS", label: "Dianópolis" },
    { id: 5, value: "GUARAI", label: "Guaraí" },
    { id: 6, value: "GURUPI", label: "Gurupi" },
    { id: 7, value: "MIRACEMA", label: "Miracema do Tocantins" },
    { id: 8, value: "PALMAS", label: "Palmas" },
    { id: 9, value: "PARAISO", label: "Paraíso do Tocantins" },
    { id: 10, value: "PEDROAFONSO", label: "Pedro Afonso" },
    { id: 11, value: "PORTONACIONAL", label: "Porto Nacional" },
    { id: 12, value: "TOCANTINOPOLIS", label: "Tocantinópolis" },
  ];

  useEffect(() => {
    if (previousData) {
      setFormData(previousData);
    }
  }, [previousData]);

  return (
    user && (
      <div>
        <CadastroForm
          titulo={"Avaliador(SRE)"}
          formData={formData}
          setFormData={setFormData}
          label={"SRE"}
          placeholder={"Selecione uma SRE"}
          tituloSelect={"regional"}
          options={options}
          isEditar={isEditar}
        />
      </div>
    )
  );
};

export default Avaliador;
