import React, { useCallback, useState } from "react";
import styled from "styled-components";

import CustomButton from "../button/Button";
import {
  formatName,
  formatCPF,
  formatDate,
  formatNumbersOnly,
} from "../../utils/format";
import Tabela from "../table/Table";
import {
  Title,
  Tabs,
  Tab,
  DataTable,
  FieldWrapper,
  FieldLabel,
  FieldValue,
  GridRow,
  Overlay,
  Content,
  ButtonsWrapper,
} from "./StyledModal";
import { StyledCopyIcon } from "../../utils/StyledUtils";
import { handleCopy } from "../../utils/HandleCopy";
import { useNavigate } from "react-router-dom";
import { slugify } from "../../utils/slugify";

const Link = styled.div`
  cursor: default;
  &:hover {
    color: #0392e0;
    cursor: pointer;
  }
`;

const ModalInfoEstudante = ({
  isOpen,
  data,
  onCancel,
  closeText = "Fechar",
  startTab = 0,
}) => {
  const [activeTab, setActiveTab] = useState(startTab);
  const navigate = useNavigate();

  const handleEscolaClick = useCallback(
    (name) => {
      navigate(`/escolas/${slugify(name)}`);
    },
    [navigate]
  );

  if (!isOpen || !data) return null;

  const pagamentos = data.pagamentos.map((p) => ({
    data: formatDate(p.dataPagamento),
    parcela: p.parcela + "ª",
    valor: p.valor,
  }));
  const headerPagamentos = ["Data do Pagamento", "Parcela", "Valor"];

  return (
    <Overlay>
      <Content>
        <Title>{formatName(data.nome)}</Title>
        <Tabs>
          <Tab $active={activeTab === 0} onClick={() => setActiveTab(0)}>
            Dados Pessoais
          </Tab>
          <Tab $active={activeTab === 1} onClick={() => setActiveTab(1)}>
            Dados de Pagamento
          </Tab>
        </Tabs>
        {activeTab === 0 && (
          <DataTable>
            <FieldWrapper>
              <FieldLabel>Escola</FieldLabel>
              <FieldValue>
                <Link onClick={() => handleEscolaClick(data.escola.name)}>
                  {data.escola.name.toUpperCase()}
                </Link>
              </FieldValue>
            </FieldWrapper>
            <GridRow>
              <FieldWrapper>
                <FieldLabel>CPF</FieldLabel>
                <FieldValue>
                  {formatCPF(data.cpf)}{" "}
                  <StyledCopyIcon
                    size={20}
                    onClick={() => handleCopy(data.cpf)}
                  />
                </FieldValue>
              </FieldWrapper>
              <FieldWrapper>
                <FieldLabel>NIS</FieldLabel>
                <FieldValue>
                  {data.nis}{" "}
                  <StyledCopyIcon
                    size={20}
                    onClick={() => handleCopy(data.nis)}
                  />
                </FieldValue>
              </FieldWrapper>
            </GridRow>
            <GridRow>
              <FieldWrapper>
                <FieldLabel>Matrícula</FieldLabel>
                <FieldValue>
                  {data.matricula}{" "}
                  <StyledCopyIcon
                    size={20}
                    onClick={() => handleCopy(data.matricula)}
                  />
                </FieldValue>
              </FieldWrapper>
              <FieldWrapper>
                <FieldLabel>Série</FieldLabel>
                <FieldValue>{data.serieDescricao}</FieldValue>
              </FieldWrapper>
            </GridRow>
            <FieldWrapper>
              <FieldLabel>Banco</FieldLabel>
              <FieldValue>{data.banco}</FieldValue>
            </FieldWrapper>
            <GridRow>
              <FieldWrapper>
                <FieldLabel>Conta</FieldLabel>
                <FieldValue>
                  {data.conta}{" "}
                  <StyledCopyIcon
                    size={20}
                    onClick={() => handleCopy(formatNumbersOnly(data.conta))}
                  />
                </FieldValue>
              </FieldWrapper>
              <FieldWrapper>
                <FieldLabel>Agência</FieldLabel>
                <FieldValue>
                  {data.agencia}{" "}
                  <StyledCopyIcon
                    size={20}
                    onClick={() => handleCopy(formatNumbersOnly(data.agencia))}
                  />
                </FieldValue>
              </FieldWrapper>
            </GridRow>
            <FieldWrapper>
              <FieldLabel>Observação</FieldLabel>
              <FieldValue>{data.observacaoUpdate}</FieldValue>
            </FieldWrapper>
          </DataTable>
        )}
        {activeTab === 1 && (
          <DataTable>
            <Tabela
              data={pagamentos}
              header={headerPagamentos}
              needTotal={false}
            />
          </DataTable>
        )}
        <ButtonsWrapper>
          <CustomButton onClick={onCancel} style={{ width: "150px" }}>
            {closeText}
          </CustomButton>
        </ButtonsWrapper>
      </Content>
    </Overlay>
  );
};

export default ModalInfoEstudante;
