import React, { useState } from 'react';

const ModalRecuperarSenha = ({ closeModal }) => {
    const [email, setEmail] = useState('');
    const [isEnviado, setisEnviado] = useState();
    const [isEnviando, setisEnviando] = useState();
    const handleResetSenha = async () => {
      try{
        let url = `${process.env.REACT_APP_BASE_URL}/auth/reset/${email}`
        setisEnviando(true);
        const response = await fetch(url,{
          method: 'POST',
          headers:{
            'Content-Type': 'application/json',
          }
        });
        if (!response.ok){
          setisEnviado(response.status);
          throw new Error(response.status);
        }
        setisEnviado(response.status);

      }catch(error){
        console.log(error);
      }
      setisEnviando(false);
    };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <span className="close-button" onClick={closeModal}>&times;</span>
        <div className='container-recuperar'>
            <h2>Recupere Sua Senha</h2>
            <p className='texto-recupe'>Digite seu email e enviaremos instruções </p>
            <p className='texto-recupe'>para recuperar sua senha!</p>

        </div>
        <div className='container-input'>
            <label className='label-input'>Digite seu Email</label>
            <input className='input-email' value={email} onChange={e => setEmail(e.target.value)} placeholder='Endereço de Email'></input>
        </div>
        <button onClick={handleResetSenha} disabled={isEnviando} className='button-email'>Enviar email de recuperação</button>
        <div className='container-status-request'>
          {isEnviando? (<>
            <div className='spinner-recu'></div>
            <p style={{color:'orange'}}>Aguarde...</p>
            </>):(
              <>
            {isEnviado==null? (<></>):
            (<>
              {isEnviado >= 200 && isEnviado < 300? (<><p style={{color:'green'}}>Email Enviado com Sucesso.</p></>):
              (<>
                {isEnviado >= 400 && isEnviado < 500 ? (<><p style={{color:'red'}}>Email inválido.</p></>):
                (<>
                  {isEnviado >= 500 && isEnviado < 600 ? (<><p style={{color:'red'}}>Erro no Serviço.</p></>):
                  (<><p style={{color:'red'}}>Código de Erro não Indentificado.</p></>)}
                </>)}
              </>)}
            </>)}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalRecuperarSenha;
