import React, { useState, useEffect, useCallback } from "react";
// import { MdOutlineSearch } from "react-icons/md";
import { useQuery } from "react-query";

//components
import { useUserContext } from "../../contexts/UserContext";
import StatusField from "../../components/informationsField/StatusField";
import Loader from "../../components/loader/Loader";
import Tabela from "../../components/table/Table";
import ModalInfo from "../../components/modal/ModalInfoEstudante";
import ModalPagamento from "../../components/modal/ModalPagamento";
import { fetchBolsistas } from "../../api/sgb-api";
import { StyledSearchIcon } from "../../utils/StyledUtils";
import { formatCPF } from "../../utils/format";

const Pagamento = () => {
  const { token } = useUserContext();
  const [bolsistasFiltrado, setBolsistasFiltrado] = useState([]);
  const [currentEstudante, setCurrentEstudante] = useState(null);
  const [modalInfoIsOpen, setModalInfoIsOpen] = useState(false);
  const [modalPagamentoIsOpen, setModalPagamentoIsOpen] = useState(false);

  const {
    data: bolsistas = [],
    error,
    isLoading,
  } = useQuery(["bolsistas", token], () => fetchBolsistas(token), {
    enabled: !!token,
  });

  const closeModalInfo = () => {
    setModalInfoIsOpen(false);
  };

  const closeModalPagamento = () => {
    setModalPagamentoIsOpen(false);
  };

  const actionButton = () => {
    setModalPagamentoIsOpen(true);
  };

  const handleViewEstudante = useCallback(
    (id) => {
      const estudante = bolsistas.find((b) => b.id === id);
      setCurrentEstudante(estudante);
      setModalInfoIsOpen(true);
    },
    [bolsistas]
  );

  useEffect(() => {
    if (bolsistas.length > 0) {
      const processedBolsistas = bolsistas.map((bolsista) => ({
        nome: bolsista.nome,
        cpf: formatCPF(bolsista.cpf),
        status: <StatusField status={bolsista.status} upperCase={true} />,
        banco: bolsista.banco,
        agencia: bolsista.agencia,
        actions: (
          <StyledSearchIcon
            size={25}
            onClick={() => handleViewEstudante(bolsista.id)}
          />
        ),
      }));
      setBolsistasFiltrado(processedBolsistas);
    }
  }, [bolsistas, handleViewEstudante]);

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const header = ["NOME", "CPF", "STATUS", "BANCO", "AGENCIA", "AÇÕES"];
  const search = ["nome", "cpf", "status", "banco", "agencia"];

  return (
    <div>
      {bolsistasFiltrado.length === 0 ? (
        <Loader />
      ) : (
        <Tabela
          data={bolsistasFiltrado}
          header={header}
          paginated={true}
          search={search}
          actionButton={actionButton}
          actionConfig={true}
          buttonLabel="Confirmar pagamento"
        />
      )}
      <ModalInfo
        isOpen={modalInfoIsOpen}
        onCancel={closeModalInfo}
        data={currentEstudante}
        startTab={1}
      />
      <ModalPagamento
        isOpen={modalPagamentoIsOpen}
        onCancel={closeModalPagamento}
        onProceed={() => {}}
        value={100}
      />
    </div>
  );
};

export default Pagamento;
