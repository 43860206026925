import styled from "styled-components";

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  text-align: left;
  background: #ebebeb;
  font-size: 18px;
  padding: 30px;
  width: auto;
  max-width: 70%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-radius: 9px;
  box-shadow: 5px 3px 4px rgba(0, 0, 0, 0.3);
`;

export const Title = styled.div`
  font-size: 1.2em;
  width: 500px;
  color: #111;
  margin: 0 auto 20px auto;
  text-align: center;
  font-weight: bold;
`;

export const Tabs = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

export const Tab = styled.div`
  padding: 10px 20px;
  cursor: pointer;
  border-bottom: ${(props) => (props.$active ? "2px solid #000" : "none")};
  color: ${(props) => (props.$active ? "#000" : "#888")};
  font-weight: ${(props) => (props.$active ? "bold" : "normal")};
`;

export const DataTable = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 10px;
  margin-bottom: 10px;
`;

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 0;
  max-width: 500px;
`;

export const FieldLabel = styled.div`
  font-size: 0.9em;
  color: #888;
  margin-bottom: 5px;
`;

export const FieldValue = styled.div`
  color: #000;
  font-weight: 500;
`;

export const GridRow = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  column-gap: 20px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 50px;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
`;
