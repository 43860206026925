import { toast } from 'react-toastify';

export const ToastType = {
  Success: 'success',
  Error: 'error',
  Warning: 'warning',
};

export const Notify = ({ message, type }) => {
  const commonOptions = {
    position: 'top-right',
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light',
    style: {
      marginTop: '80px',
    },
  };

  if (type === ToastType.Success) {
    toast.success(message, commonOptions);
  } else if (type === ToastType.Error) {
    toast.error(message, commonOptions);
  }else if (type === ToastType.Warning){
    toast.warning(message, commonOptions);
  }
};
