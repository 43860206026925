import * as ExcelJS from "exceljs";
import { genereateBasePlanilha, regularColunas, bordaArea } from "../uteis/Generate";


const DownloadExcelEscolas = async (escolas) => {
  // Criar um novo Livro de worokBoook
  const wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet("Escolas");
  const titulo = "Planilha de Registro de Escolas do Sistema de Gerencias de Bolsas - SGB"
  const subtitulo = "Escolas Cadastradas"
  genereateBasePlanilha(escolas, ws, wb, titulo, subtitulo);

  // Adicionar cabeçalho
  const headers = Object.keys(escolas[0]);
  const tb = ws.addRow(headers);

  tb.eachCell((cell, colNumber) => {
    cell.font = { bold: true,};
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '5080AB' } // Azul
    };
    cell.alignment = { vertical: 'middle', horizontal: 'center' };
  });

  // Adicionar os dados
  escolas.forEach(row => {
    ws.addRow(Object.values(row));
  });

  // Ajusta a largura das colunas
  regularColunas(ws)

  // Adiciona Bordas
  bordaArea(escolas, ws)

  // Salvar o workbook como XLSX
  var FileSaver = require('file-saver');
  const doc = await wb.xlsx.writeBuffer("escola.xlsx")
  FileSaver.saveAs(new Blob([doc]), "Escolas.xlsx");
}

export default DownloadExcelEscolas;
