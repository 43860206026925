import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "../Colors";

const StyledButton = styled.button`
  border-radius: 5px;
  text-align: center;
  font-size: 20px;
  padding: 10px;
  border-color: transparent;
  width: 100%;
  height: 50px;
  font-weight: 700;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 15px;
  transition: background 0.3s ease;
`;

const buttonVariantStyles = {
  default: {
    backgroundColor: colors.cinzaSecundario,
    color: colors.brancoPrimario,
    hover: {
      backgroundColor: colors.cinzaSecundario,
    },
    active: {
      backgroundColor: colors.cinzaSecundario,
      animation: "blink 0.3s ease",
    },
  },
  info: {
    backgroundColor: colors.azulPrimario,
    color: colors.brancoPrimario,
    hover: {
      backgroundColor: colors.azulPrimario,
    },
    active: {
      backgroundColor: colors.azulButton,
      animation: "blink 0.3s ease",
    },
  },
  cancel: {
    color: colors.brancoPrimario,
    backgroundColor: colors.vermelhoSecundario,
    hover: {
      backgroundColor: colors.vermelhoPrimario,
    },
    active: {
      backgroundColor: colors.vermelhoPrimario,
      animation: "blink 0.3s ease",
    },
  },
};

const CustomButton = ({
  variant = "info",
  children,
  type = "button",
  style = {},
  ...props
}) => {
  const [isHover, setIsHover] = useState(false);

  const baseStyle = buttonVariantStyles[variant] || {};
  const hoverStyle = isHover && baseStyle.hover ? baseStyle.hover : {};

  const combinedStyle = { ...baseStyle, ...hoverStyle, ...style };

  return (
    <StyledButton
      $variant={variant}
      type={type}
      style={combinedStyle}
      {...props}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={props.onClick}
    >
      {children}
    </StyledButton>
  );
};

export default CustomButton;
