export const slugify = (text) => {
  return text
    .toString()
    .normalize('NFD')  // Normaliza o texto em forma NFD (Normalization Form Decomposition)
    .replace(/[\u0300-\u036f]/g, '')  // Remove marcas de diacríticos
    .toLowerCase()
    .replace(/\s+/g, '-')  // Substitui espaços por hífens
    .replace(/[^\w-]+/g, '')  // Remove todos os caracteres não alfanuméricos
    .replace(/--+/g, '-')  // Substitui múltiplos hífens por um único hífen
    .replace(/^-+/, '')  // Remove hífens do início do texto
    .replace(/-+$/, '');  // Remove hífens do final do texto
};
