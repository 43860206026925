import React, { useState } from 'react';
import { useUserContext } from "../../contexts/UserContext";
import { useNavigate } from "react-router-dom";
import Container from "../../components/container/Container";
import Tooltip from '../../components/tooltip/Tooltip';
import { StyledDeleteIcon, StyledAddIcon, StyledArrowIcon } from '../../utils/StyledUtils';
import styled from 'styled-components';
import { useDataContext } from '../../contexts/DataContext';
import { FaCircle } from "react-icons/fa";
import Modal from '../../components/modal/ModalGenerico';

const Button = styled.button`
  border-radius: 15px;
  text-align: center;
  font-size: 20px;
  padding: 10px;
  color: #fff;
  background-color: #0062bc;
  border: none;
  height: 45px;
  cursor: pointer;
  transition: background 0.3s ease;

  &:hover {
    background-color: #0070d1;
  }

  &:disabled {
    background-color: #b0bec5;
    cursor: not-allowed;
  }
`;

const Paragraph = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  -webkit-box-orient: vertical;
`;

const Content = styled.div`
  text-align: left;
`;

const AddButtonContainer = styled.div`
  position: relative;
  left: -30%;
  padding: 10px;
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const StyledArrowIconWrapper = styled.div`
  position: relative;
`;

const StyledDeleteIconWrapper = styled.div`
  position: relative;
  top: 15px;
`;

const Notifications = () => {
  const { user } = useUserContext();
  const { mensagens, mutationDeleteMensagem } = useDataContext();
  const [currentNotificacao, setCurrentNotificacao] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const navigate = useNavigate();
  const isAdmin = user.profile === "ADMIN";

  const handleButtonClick = () => {
    navigate("cadastro", { state: { user } });
  };

  const formatarData = (dataString) => {
    const data = new Date(dataString);
    const dia = String(data.getDate() + 1).padStart(2, '0');
    const mes = String(data.getMonth() + 1).padStart(2, '0');
    const ano = data.getFullYear();
    return `${dia}/${mes}/${ano}`;
  };

  const handleViewNotification = (id) => {
    const mensagem = mensagens.find((b) => b.id === id);
    navigate("visualizar", { state: { previousData: mensagem } });
  }

  const openConfirmDelete = (id) => {
    setCurrentNotificacao(id);
    setConfirmDelete(true);
  };

  const closeConfirmDelete = () => setConfirmDelete(false);

  const handleDeleteNotificacao = async () => {
    try {
      await mutationDeleteMensagem.mutateAsync(currentNotificacao);
    } catch (error) {
      console.error("Erro ao excluir notificacao:", error);
    } finally {
      closeConfirmDelete();
    }
  };

  return (
    <>
      {isAdmin && (
        <AddButtonContainer>
          <Tooltip text="Adicionar uma nova notificação">
            <Button onClick={handleButtonClick}>
              <StyledAddIcon size={25} />
            </Button>
          </Tooltip>
        </AddButtonContainer>
      )}
      <Container style={{ marginTop: '10px' }}>
        <Content>
          {mensagens.slice().reverse().map((mensagem) => (
            <div key={mensagem.id}>
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                  <div>
                    <h2 style={{ color: mensagem.ehlido ? "black" : "grey" }}>
                      {!mensagem.ehlido && <FaCircle color="red" size={15} />}
                      {mensagem.titulo}
                    </h2>
                    <small>
                      <i style={{ color: "black" }}>
                        {formatarData(mensagem.dataCadastro)} - {mensagem.remetente.profile}
                      </i>
                    </small>
                  </div>
                  <IconContainer>
                    <StyledArrowIconWrapper>
                      <StyledArrowIcon size={30} onClick={() => handleViewNotification(mensagem.id)} />
                    </StyledArrowIconWrapper>
                    {isAdmin && (
                      <StyledDeleteIconWrapper>
                        <StyledDeleteIcon size={20} onClick={() => openConfirmDelete(mensagem.id)} />
                      </StyledDeleteIconWrapper>
                    )}
                  </IconContainer>
                </div>
              </div>
              <Paragraph>{mensagem.corpo}</Paragraph>
              <hr />
            </div>
          ))}
          {confirmDelete && (
            <Modal
              isOpen={confirmDelete}
              text={`Deseja mesmo deletar está notificação?`}
              onProceed={handleDeleteNotificacao}
              onCancel={closeConfirmDelete}
              textoOk="Deletar"
              textoCancelar="Cancelar"
            />
          )}
        </Content>
      </Container>
    </>
  );
}

export default Notifications;
