import imgPlanilha from '../../../Assets/planilha_img.png';

/**
 * Cria uma planilha Excel estilizada com os dados fornecidos.
 *
 * @param {ExcelJS.Worksheet} ws - Objeto Worksheet do ExcelJS.
 * @param {Object[]} dados - Array de objetos contendo dados das escolas.
 */

async function genereateBasePlanilha(dados, ws, wb, titulo, subtitulo) {
     const colunas = Object.keys(dados[0]);
     //Logo
     ws.mergeCells(1, 1, 7, colunas.length)
     ws.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' };
     ws.getCell('A1').border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
     };

     //Titulo
     ws.mergeCells(8, 1, 8, colunas.length)
     ws.getCell('A8').value = titulo;
     ws.getCell('A8').alignment = { vertical: 'middle', horizontal: 'center' };
     ws.getCell('A8').font = { bold: true, size: 16 };
     ws.getCell('A8').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'A6A6A6' } // Cinza
      };
     ws.getCell('A8').border = {
          top: { style: 'medium' },
          bottom: { style: 'medium' },
          left: { style: 'thin' },
          right: { style: 'thin' }
     };

     //SubTitulo
     ws.mergeCells(9, 1, 9, colunas.length)
     ws.getCell('A9').value = subtitulo;
     ws.getCell('A9').alignment = { vertical: 'middle', horizontal: 'center' };
     ws.getRow(9).height = 40;
     ws.getCell('A9').font = { bold: true, size: 11};
     ws.getCell('A9').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '2F75B5' } // Azul
      };
     ws.getCell('A9').border = {
          top: { style: 'medium' },
          bottom: { style: 'medium' },
          left: { style: 'thin' },
          right: { style: 'thin' }
     };
     ws.mergeCells(10, 1, 10, colunas.length)

     // Obtenha a imagem base64
    const base64Image = await getBase64FromUrl(imgPlanilha);

     // Adicionar a imagem ao workbook
     const imageId = wb.addImage({
          base64: base64Image,
          extension: 'png', // Extensão da imagem
     });

     // Posicionar a imagem na célula desejada (exemplo: na célula 'B2')
     ws.addImage(imageId, {
          tl: { col:0 , row: 0 }, // top-left corner: Coluna A, Linha 1
          ext: { width: 20, height: 10 } // Dimensões da imagem
     });

}

// Função para converter imagem para base64
const getBase64FromUrl = async (url) => {
     const response = await fetch(url);
     const blob = await response.blob();
     return new Promise((resolve, reject) => {
       const reader = new FileReader();
       reader.onloadend = () => {
         resolve(reader.result.split(",")[1]);
       };
       reader.onerror = reject;
       reader.readAsDataURL(blob);
     });
   };

async function regularColunas(ws){
     // Ajustar largura das colunas
     ws.columns.forEach(column => {
          let maxLength = 0;
          column.eachCell({ includeEmpty: true }, cell => {
          if (cell.row > 10){
          const columnLength = cell.value ? cell.value.toString().length : 10;
          if (columnLength > maxLength) {
               maxLength = columnLength;
          }}
          });
          column.width = maxLength < 10 ? 10 : maxLength + 3;
     });
}

async function bordaArea(dados, ws){
     const cols = Object.keys(dados[0]).length
     // Definir bordas para a tabela
     const primeiraLinha = ws.getRow(11);
     const ultimaLinha = ws.getRow(dados.length+11);
     const primeiraColuna = ws.getColumn(1);
     const ultimaColuna = ws.getColumn(cols);

     // Aplicar bordas às células da primeira coluna
     primeiraColuna.eachCell((cell, rowNumber) => {
          if ( cell.row > 10){
               cell.border = {
                    left: { style: 'medium' }
               };
          }
     });
     ultimaColuna.eachCell((cell, rowNumber) => {
          if ( cell.row > 10){
               cell.border = {
                    right: { style: 'medium' }
               };
          }
     });

     // Aplicar bordas às células da primeira linha (cabeçalho)
     primeiraLinha.eachCell((cell, colNumber) => {
          if( cell.col === cols){
               cell.border = {
                    top: { style: 'medium' },
                    right: { style: 'medium' }
                    };
          }
          else if(cell.col === 1){
               cell.border = {
                    top: { style: 'medium' },
                    left: { style: 'medium' }
                    };
          }
          else{
               cell.border = {
               top: { style: 'medium' }
               };
          }

     });

     // Aplicar bordas às células da última linha
     ultimaLinha.eachCell((cell, colNumber) => {
          if( cell.col === cols){
               cell.border = {
                    bottom: { style: 'medium' },
                    right: { style: 'medium' }
                    };
          }
          else if(cell.col === 1){
               cell.border = {
                    bottom: { style: 'medium' },
                    left: { style: 'medium' }
                    };
          }
          else{
               cell.border = {
               bottom: { style: 'medium' }
               };
          }
     });
}

export {genereateBasePlanilha, regularColunas, bordaArea};
