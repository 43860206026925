import React from "react";
import styled from "styled-components";

const StyledContainer = styled.div`
  width: clamp(400px, 60%, 100%);
  margin: auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
`;

const Titulo = styled.div`
  font-size: 1.5em;
  color: #111;
  margin-bottom: 20px;
  text-align: center;
  font-weight: bold;
`;

const Container = ({ children, titulo, style }) => {
  return (
    <StyledContainer style={style}>
      {titulo && <Titulo>{titulo}</Titulo>}
      {children}
    </StyledContainer>
  );
};

export default Container;
