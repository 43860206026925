import React from "react";
import { Outlet } from "react-router-dom";
import { DataProvider } from "../../contexts/DataContext";

function LayoutContext() {
  return (
    <DataProvider>
      <Outlet />
    </DataProvider>
  );
}

export default LayoutContext;
