import React from "react";
import styled from "styled-components";
import { LuSchool } from "react-icons/lu";
import { PiStudent } from "react-icons/pi";
import { CiMoneyCheck1 } from "react-icons/ci";

import { useDataContext } from "../../contexts/DataContext";
import DownloadExcelEscolas from "./download/DownloadExcelEscolas";
import DownloadExcelBolsistas from "./download/DownloadExcelBolsistas";
import DownloadExcelFinanceiro from "./download/DownloadExcelFinanceiro";
import { useUserContext } from "../../contexts/UserContext";

const PageContainer = styled.div`
  padding: 30px 80px;
`;

const Header = styled.h1`
  font-size: 2em;
  margin-bottom: 10px;
`;

const Description = styled.p`
  margin-bottom: 50px;
  font-size: 1.2em;
`;

const CategoryContainer = styled.div`
  margin-bottom: 20px;
`;

const CategoryTitle = styled.h2`
  cursor: pointer;
  background: #ddd;
  padding: 10px;
  border-radius: 10px;
`;

const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
`;

const ListItemInfo = styled.div`
  display: flex;
  text-align: left;
`;

const Icon = styled.span`
  margin-right: 10px;
`;

const DownloadButton = styled.button`
  width: 100px;
  height: 40px;
  padding: 10px 20px;
  background: #0062bc;
  border-radius: 7px;
  color: white;
  font-size: 20px;
  border: none;
  cursor: pointer;
`;

const Planilhas = () => {
  const { bolsistas, escolas } = useDataContext();
  const { user } = useUserContext();
  const isAllowedEscolas =
    user.profile === "ADMIN" || user.profile === "AVALIADOR";
  const isAllowedPagamentos = user.profile === "ADMIN";

  
  const handleDownloadBolsistas = (status, fileName = "Bolsistas.xlsx") => {
    let bolsistasFiltrados = [...bolsistas];
    
    if (status) {
      bolsistasFiltrados = bolsistasFiltrados.filter((bolsista) => {
        if (status === "novosativo") {
          return bolsista.status === 'ativo' && bolsista.ehNovoEstudante === true;
        } else if (status === "ativo") {
          return bolsista.status === 'ativo' && bolsista.ehNovoEstudante === false;
        } else if (status === 'ativos'){
           return bolsista.status === 'ativo';
        }
        return bolsista.status === status;
      });
    }

    bolsistasFiltrados.sort((a, b) => a.escola.name.localeCompare(b.escola.name));
    DownloadExcelBolsistas(bolsistasFiltrados, fileName);
  };

  const handleDownloadEscolas = () => {
    DownloadExcelEscolas(escolas);
  };

  const handleDownloadFinanceiro = () => {
    DownloadExcelFinanceiro(bolsistas);
  };

  const data = [
    isAllowedEscolas && {
      category: "Escolas",
      items: [
        {
          name: "Planilha de Escolas",
          description: "Lista de todas as escolas",
          icon: <LuSchool size={50} />,
          buttonAction: handleDownloadEscolas,
        },
      ],
    },
    {
      category: "Estudantes",
      items: [
        {
          name: "Bolsistas Geral",
          description: "Lista de todos os bolsistas",
          icon: <PiStudent size={50} />,
          buttonAction: () => handleDownloadBolsistas(),
        },
        {
          name: "Bolsistas Homologados",
          description: "Lista somente dos bolsistas homologados",
          icon: <PiStudent size={50} />,
          buttonAction: () =>
            handleDownloadBolsistas("ativo", "BolsistasHomologados.xlsx"),
        },
        {
          name: "Bolsistas Novos Homologados",
          description: "Lista somente dos bolsistas novos homologados",
          icon: <PiStudent size={50} />,
          buttonAction: () =>
            handleDownloadBolsistas("novosativo", "BolsistasNovosHomologados.xlsx"),
        },
        {
          name: "Bolsistas Indeferidos",
          description: "Lista somente dos bolsistas indeferidos",
          icon: <PiStudent size={50} />,
          buttonAction: () =>
            handleDownloadBolsistas("indeferido", "BolsistasIndeferidos.xlsx"),
        },
        {
          name: "Bolsistas Cadastrados",
          description: "Lista somente dos bolsistas cadastrados",
          icon: <PiStudent size={50} />,
          buttonAction: () =>
            handleDownloadBolsistas("pendente", "BolsistasPendentes.xlsx"),
        },
      ],
    },
    isAllowedPagamentos && {
      category: "Pagamento",
      items: [
        {
          name: "Planilha de pagamento",
          description: "Lista com os dados para pagamento",
          icon: <CiMoneyCheck1 size={50} />,
          buttonAction: handleDownloadFinanceiro,
        },
        {
          name: "Bolsistas Aptos",
          description: "Lista dos bolsistas aptos a receber o pagamento",
          icon: <CiMoneyCheck1 size={50} />,
          buttonAction: () =>
            handleDownloadBolsistas("ativos", "BolsistasAtivos.xlsx"),
        },
      ],
    },
  ].filter(Boolean);

  return (
    <PageContainer>
      <Header>Planilhas</Header>
      <Description>Selecione o tipo de planilha que deseja baixar.</Description>
      {data.map((category, index) => (
        <CategoryContainer key={index}>
          <CategoryTitle>{category.category}</CategoryTitle>
          {category.items.map((item, idx) => (
            <ListItem key={idx}>
              <ListItemInfo>
                <Icon>{item.icon}</Icon>
                <div>
                  <strong>{item.name}</strong>
                  <p>{item.description}</p>
                </div>
              </ListItemInfo>
              <DownloadButton onClick={item.buttonAction}>
                Baixar
              </DownloadButton>
            </ListItem>
          ))}
        </CategoryContainer>
      ))}
    </PageContainer>
  );
};

export default Planilhas;
