import React, { useState, useEffect } from "react";

import Table from "../../components/table/Table";
import StatusBoolean from "../../components/informationsField/StatusBoolean";
import { useDataContext } from "../../contexts/DataContext";
import { useUserContext } from "../../contexts/UserContext";

const Audit = () => {
  const { user, setIsDisabledUpdate } = useUserContext();
  const { audits, mutationPostAudit } = useDataContext();
  const [auditsFiltrado, setAuditsFiltrado] = useState([]);

  const formatarData = (dataString) => {
    const data = new Date(dataString);
    const dia = String(data.getDate() + 1).padStart(2, '0');
    const mes = String(data.getMonth() + 1).padStart(2, '0');
    const ano = data.getFullYear();
    return `${dia}/${mes}/${ano}`;
  };

  useEffect(() => {
    if (audits.length > 0) {
      const processedAudits = audits.map((audit) => ({
        id: audit.id,
        data: formatarData(audit.date),
        tipo: audit.type,
        responsavel: audit.responsable?.nomeCompleto,
        bolsistasRestantesParaAtualizar: audit.bolsistasRestantesParaAtualizar,
        bemSucedido: <StatusBoolean status={audit.bemSucedido} />,
      }));
      setAuditsFiltrado(processedAudits);
    }
  }, [audits]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const placeholderAudit = {
        id: auditsFiltrado.length + 1,
        data: formatarData(new Date().toISOString()),
        tipo: "recover",
        responsavel: user.nomeCompleto,
        bolsistasRestantesParaAtualizar: '---',
        bemSucedido: <StatusBoolean />,
      };

      setAuditsFiltrado([...auditsFiltrado, placeholderAudit]);
      setIsDisabledUpdate(true);

      const result = await mutationPostAudit.mutateAsync();

      const bemSucedidoStatus = result.bemSucedido;
      const bolsistasRestantes = result.bolsistasRestantes || '---';

      setAuditsFiltrado((prevAudits) =>
        prevAudits.map((audit) =>
          audit.id === placeholderAudit.id
            ? {
                ...audit,
                bolsistasRestantesParaAtualizar: bolsistasRestantes,
                bemSucedido: <StatusBoolean status={bemSucedidoStatus} />,
              }
            : audit
        )
      );
      setIsDisabledUpdate(false);
    } catch (error) {
      console.error("Error submitting form:", error);
      setIsDisabledUpdate(false);
    }
  };

  const header = ["ID", "DATA", "TIPO", "RESPONSÁVEL", "BOLSISTAS PARA ATUALIZAR", "SITUAÇÃO"];
  const search = ["responsavel", "tipo"];

  return (
    <div>
      <Table
        data={auditsFiltrado}
        header={header}
        paginated={true}
        search={search}
        actionUpdate={true}
        handleUpdate={handleSubmit}
      />
    </div>
  );
};

export default Audit;
