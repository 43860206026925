import React from "react";
import styled from "styled-components";

import { colors } from "../Colors.js";
import "./card.css";

const cardVariantStyles = {
  green: {
    color: colors.brancoPrimario,
    backgroundColor: colors.verdePrimario,
    hoverColor: "#339a61", //TODO adicionar em { colors } depois que tiver decidido quais cores
  },
  blue: {
    color: colors.brancoPrimario,
    backgroundColor: colors.azulPrimario,
    hoverColor: colors.azulSecundario,
  },
  yellow: {
    color: colors.brancoPrimario,
    backgroundColor: colors.amareloPrimario,
    hoverColor: "#088A85",
  },
  red: {
    color: colors.brancoPrimario,
    backgroundColor: colors.vermelhoPrimario,
    hoverColor: "#cd6b6b",
  },
  oceanBlue: {
    color: colors.brancoPrimario,
    backgroundColor: colors.azulMarinhoPrimario,
    hoverColor: "#609ab4",
  },
  deactivate: {
    color: colors.brancoPrimario,
    backgroundColor: "#444",
  }
};

const CustomCard = ({
  variant = "green",
  type,
  icon: IconComponent,
  children,
  style = {},
  item,
  ...props
}) => {

  const baseStyle = cardVariantStyles[variant] || {};

  const combinedStyle = { ...baseStyle, ...style };
  const isMultiWordTitle = item.title.split(" ").length > 1;
  const titleClass = `card-text title-p ${
    isMultiWordTitle ? "translate font-small title-bottom" : "font-large"
  } ${item.isValue ? "title-bottom" : ""}`;

  const renderCardContent = () => {
    switch (type) {
      case "titleIcon":
        return (
          <div className="content-type">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingLeft: "30px",
                paddingRight: "30px",
              }}
            >
              <span
                className={titleClass}
                style={{ color: combinedStyle.color }}
              >
                {item.title}
              </span>
              {IconComponent && (
                <IconComponent
                  style={{ color: combinedStyle.color, paddingTop: "10px" }}
                  size={90}
                />
              )}
            </div>
          </div>
        );
      case "titleIconValue":
        return (
          <div className="content-type">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingLeft: "25px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                }}
              >
                <span
                  className="card-text total-p"
                  style={{
                    color: combinedStyle.color,
                    marginBottom: "15px",
                    paddingRight: "90%",
                  }}
                >
                  {item.value}
                </span>
                <span
                  className={titleClass}
                  style={{ color: combinedStyle.color }}
                >
                  {item.title}
                </span>
              </div>
              {IconComponent && (
                <IconComponent
                  style={{
                    color: combinedStyle.color,
                    margin: "10px 10px 20px -30px",
                  }}
                  size={90}
                />
              )}
            </div>
          </div>
        );
      case "titleValue":
        return (
          <div className="content-type">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingBottom: "50px",
                paddingLeft: "25px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                }}
              >
                <span
                  className="card-text total-p"
                  style={{ color: combinedStyle.color }}
                >
                  {item.value}
                </span>
                <span
                  className="card-text title-p font-large"
                  style={{ color: combinedStyle.color }}
                >
                  {item.title}
                </span>
              </div>
              {IconComponent && (
                <IconComponent
                  style={{
                    color: combinedStyle.color,
                    margin: "10px 10px 0 0",
                  }}
                  size={90}
                />
              )}
            </div>
          </div>
        );
      case "description":
        return (
          <div className="content-type">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingLeft: "25px",
              }}
            >
              <span
                className={titleClass}
                style={{ color: combinedStyle.color }}
              >
                {item.title}
              </span>
              {IconComponent && (
                <IconComponent
                  style={{
                    color: combinedStyle.color,
                    margin: "10px 10px 0 0",
                  }}
                  size={90}
                />
              )}
            </div>
            <div>
              <p className="card-text description-p">{item.description}</p>
              <p className="card-text contrato-p">{item.contrato}</p>
              <p className="card-text cargo-p">{item.cargo}</p>
            </div>
          </div>
        );
      default:
        return (
          <div className="content-type">
            <div style={{ paddingTop: "50px", paddingLeft: "25px" }}>
              <span
                className={titleClass}
                style={{ color: combinedStyle.color }}
              >
                {item.title}
              </span>
            </div>
          </div>
        );
    }
  };

  return (
    <StyledCardBackground
      className={"menu-items"}
      {...props}
      onClick={item.buttonAction}
      $baseStyle={baseStyle}
    >
      <div className="card-body content">{renderCardContent()}</div>
      <div
        className="card-body"
        style={combinedStyle}
      />
    </StyledCardBackground>
  );
};

const StyledCardBackground = styled.div`
  background-color: ${(props) => props.$baseStyle.backgroundColor};
  &:hover {
    background-color: ${(props) => props.$baseStyle.hoverColor};
  }
`;

export default CustomCard;
