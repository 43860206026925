import React from "react";
import Container from "../../components/container/Container";

const Sobre = () => {
  return (
    <Container titulo="Sobre">
      <p>
        O SGB (Sistema de Gerenciamento de Bolsistas) é um sistema da Diretoria de Educação Integral e Jornada Ampliada
         do Tocantins, desenvolvido pela Gerência de Sistemas da Diretoria de Tecnologia da Informação no ano de 2024 com o intuito de{" "}
        <strong>
          automatizar, aprimorar e armazenar de forma segura e eficiente os dados de estudantes bolsistas
           das escolas do estado que estão aderidos ao programa de "Bolsa Permanência"
        </strong>
        .
      </p>
      <br />
      <p>
        O sistema conta com 4 perfis, secretário, multiprofissional, avaliador e administrador, tendo
        cada um destes sua função específica a desempenhar. Para mais informações sobre o desempenho de cada uma das funções,
         vídeos explicativos estarão disponíveis nos arquivos do sistema.
      </p>
      <br />
      <p>
        Como usuário ativo do programa SGB é de suma importância que o
        cadastro de qualquer bolsista, escola e usuário seja precedido por uma revisão de todos
        os campos preenchidos pelo próprio usuário antes de salvar no sistema
        para facilitar o fluxo e reduzir o tempo de espera dos processos.
      </p>
      <br />
      <p>
        Ressaltamos que com o objetivo de padronizar e criar imagens ou layouts
        para os sistemas em desenvolvimento, a Gerência de
        Sistemas em parceria à Diretoria de Comunicação, realiza a criação e
        validação da parte gráfica, visando garantir a coerência visual com a
        identidade da Seduc – TO.
      </p>
      <br />
      <p>
        Por último, a equipe da gerência de sistemas agradece a preferência e
        deseja uma boa experiência a todos os usuários.
      </p>
    </Container>
  );
};

export default Sobre;
