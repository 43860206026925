import styled from "styled-components";
import {
  MdOutlineSearch,
  MdContentCopy,
  MdOutlineEdit,
  MdDeleteOutline,
  MdOutlineAdd,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";
import { RxUpdate } from "react-icons/rx";
import { IoSettingsOutline } from "react-icons/io5";

export const StyledSearchIcon = styled(MdOutlineSearch)`
  cursor: default;
  color: black;
  transition: color 0.3s;

  &:hover {
    cursor: pointer;
    color: #0062bc;
  }
`;

export const StyledCopyIcon = styled(MdContentCopy)`
  cursor: default;

  &:hover {
    cursor: pointer;
  }
`;

export const StyledUpdateIcon = styled(RxUpdate)`
  cursor: default;

  &:hover {
    cursor: pointer;
  }
`;

export const StyledEditIcon = styled(MdOutlineEdit)`
  cursor: default;
  color: black;
  transition: color 0.3s;

  &:hover {
    cursor: pointer;
    color: #0062bc;
  }
`;



export const StyledDeleteIcon = styled(MdDeleteOutline)`
  cursor: default;
  color: black;
  transition: color 0.3s;

  &:hover {
    cursor: pointer;
    color: red;
  }
`;

export const StyledAddIcon = styled(MdOutlineAdd)`
  cursor: default;

  &:hover {
    cursor: pointer;
  }
`;

export const StyledArrowIcon = styled(MdKeyboardDoubleArrowRight)`
  cursor: default;
  color: black;
  transition: color 0.3s;

  &:hover {
    cursor: pointer;
    color: green;
  }
`;


export const StyledSettingsIcon = styled(IoSettingsOutline)`
  cursor: default;

  &:hover {
    cursor: pointer;
  }
`;

export const NoDataMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  color: #555;
  font-size: 1.5rem;
  background-color: #f0f0f0;
  border: 2px dashed #ccc;
  border-radius: 10px;
  margin: 20px;
  padding: 20px;
  text-align: center;
`;

export const IconContainerTable = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  flex-wrap: nowrap;
`;
