import React from "react";
import styled from "styled-components";

import { formatNumberWithDot } from "../../utils/format";

const Total = styled.div`
  background-color: #fff;
  font-size: 18px;
  padding: 7px;
  border-radius: 15px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
`;

const TotalCount = ({ count }) => (
  <Total>
    <b>Total:</b> {formatNumberWithDot(count)}
  </Total>
);

export default TotalCount;
