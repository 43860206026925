import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import Table from "../../components/table/Table";
import ModalInfoUser from "../../components/modal/ModalInfoUser";
import { useDataContext } from "../../contexts/DataContext";
import {
  IconContainerTable,
  StyledSearchIcon,
  StyledEditIcon,
  StyledDeleteIcon,
} from "../../utils/StyledUtils";
import { formatCPF } from "../../utils/format";
import Modal from "../../components/modal/ModalGenerico";
import Tooltip from "../../components/tooltip/Tooltip";

const UsuariosLista = () => {
  const { profiles, mutationDeleteProfile } = useDataContext();
  const [usuariosFiltrado, setUsuariosFiltrado] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState(false);
  const [currentUsuario, setCurrentUsuario] = useState(null);
  const navigate = useNavigate();

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const openModalDelete = (usuario) => {
    setCurrentUsuario(usuario);
    setModalDeleteIsOpen(true);
  };

  const closeModalDelete = () => {
    setModalDeleteIsOpen(false);
  };

  const confirmDeleteAction = () => {
    handleDeleteUser(currentUsuario.id);
    closeModalDelete();
  };

  const getCurrentUserName = () => {
    if (currentUsuario) {
      return currentUsuario.nomeCompleto;
    } else {
      return "";
    }
  };
  const handleViewUsuario = useCallback(
    (id) => {
      const usuario = profiles.find((b) => b.id === id);
      setCurrentUsuario(usuario);
      setModalIsOpen(true);
    },
    [profiles]
  );

  const handleEditUser = useCallback(
    (data) => {
      switch (data.profile) {
        case "ADMIN":
          navigate("/cadastro/administrador", {
            state: { previousData: data },
          });
          break;
        case "AVALIADOR":
          navigate("/cadastro/avaliador-sre", {
            state: { previousData: data },
          });
          break;
        case "SECRETARIO":
          navigate("/cadastro/secretario", { state: { previousData: data } });
          break;
        case "MULTIPROFISSIONAL":
          navigate("/cadastro/multiprofissional", {
            state: { previousData: data },
          });
          break;
        default:
          break;
      }
    },
    [navigate]
  );

  const handleDeleteUser = async (id) => {
    try {
      await mutationDeleteProfile.mutateAsync(id);
    } catch (error) {
      console.error("Erro ao excluir perfil:", error);
    } finally {
      closeModal();
    }
  };

  useEffect(() => {
    if (profiles.length > 0) {
      const processedUsuarios = profiles.map((usuario) => ({
        nome: usuario.nomeCompleto.toUpperCase(),
        cpf: formatCPF(usuario.cpf),
        tipo: usuario.profile,
        escola: usuario.escola
          ? usuario.escola.name.toUpperCase()
          : usuario.setor
          ? usuario.setor
          : usuario.regional,
        acoes: (
          <IconContainerTable>
            <StyledSearchIcon
              size={25}
              onClick={() => handleViewUsuario(usuario.id)}
            />
            <Tooltip text="Editar usuário">
              <StyledEditIcon
                size={25}
                onClick={() => handleEditUser(usuario)}
              />
            </Tooltip>
            <StyledDeleteIcon
              size={25}
              onClick={() => openModalDelete(usuario)}
            />
          </IconContainerTable>
        ),
      }));
      setUsuariosFiltrado(processedUsuarios);
    }
  }, [profiles, handleViewUsuario, handleEditUser]);

  const header = ["NOME", "CPF", "TIPO", "ESCOLA / REGIONAL / SETOR", "AÇÕES"];
  const search = ["nome", "cpf", "tipo", "escola"];

  return (
    <div>
      <Table
        data={usuariosFiltrado}
        header={header}
        paginated={true}
        search={search}
      />
      <ModalInfoUser
        isOpen={modalIsOpen}
        onCancel={closeModal}
        handleEdit={handleEditUser}
        handleDelete={handleDeleteUser}
        data={currentUsuario}
      />
      <Modal
        isOpen={modalDeleteIsOpen}
        text={`Deseja mesmo deletar ${getCurrentUserName()}?`}
        onProceed={confirmDeleteAction}
        onCancel={closeModalDelete}
        textoOk="Deletar"
        textoCancelar="Cancelar"
      />
    </div>
  );
};

export default UsuariosLista;
