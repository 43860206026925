import React from 'react'

// ícones
import { MdOutlineDashboard } from "react-icons/md";
import { FaRegFileExcel } from "react-icons/fa6";
import { CiMoneyCheck1 } from "react-icons/ci";
import { PiStudent } from "react-icons/pi";
import { LuSchool } from "react-icons/lu";
import { AiOutlineFileAdd } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { FaUsers } from 'react-icons/fa';

//components
import CardGroup from "../../components/card/CardGroup";
import { formatNumberWithDot } from '../../utils/format';

const HomeAdmin = ({ totalEscolas, totalBolsistas }) => {
  const navigate = useNavigate();

  const handleClick = (path) => {
    navigate(path);
  };

  const itens = [
    {
      id: 0,
      title: "Dashboard",
      variant: "blue",
      type: "titleIcon",
      icon: MdOutlineDashboard,
      buttonAction: () => {
        handleClick("/dashboard");
      },
    },
    {
      id: 1,
      title: "Planilhas",
      variant: "blue",
      type: "titleIcon",
      icon: FaRegFileExcel,
      buttonAction: () => {
        handleClick("/planilhas");
      },
    },
    {
      id: 2,
      title: "Pagamento",
      variant: "blue",
      type: "titleIcon",
      icon: CiMoneyCheck1,
      buttonAction: () => {
        handleClick("/pagamento");
      },
    },
    {
      id: 3,
      title: "Estudantes",
      variant: "blue",
      type: "titleIconValue",
      value: formatNumberWithDot(totalBolsistas),
      icon: PiStudent,
      buttonAction: () => {
        handleClick("/estudantes");
      },
    },
    {
      id: 4,
      title: "Escolas",
      variant: "blue",
      type: "titleIconValue",
      value: formatNumberWithDot(totalEscolas),
      icon: LuSchool,
      buttonAction: () => {
        handleClick("/escolas");
      },
    },
    {
      id: 5,
      title: "Usuários",
      variant: "blue",
      type: "titleIcon",
      icon: FaUsers,
      buttonAction: () => {
        handleClick("/usuarios");
      },
    },
    {
      id: 6,
      title: "Cadastros",
      variant: "blue",
      type: "titleIcon",
      icon: AiOutlineFileAdd,
      buttonAction: () => {
        handleClick("/cadastros");
      },
    },
    // {
    //   id: 7,
    //   title: "Relatórios",
    //   variant: "blue",
    //   type: "titleIcon",
    //   icon: FaRegFileLines,
    //   buttonAction: () => {
    //     handleClick("/relatorios");
    //   },
    // },
  ];
  return (
    <CardGroup menuItems={itens}/>
  )
}

export default HomeAdmin
