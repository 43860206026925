import React, { useEffect, useState } from "react";

import { useUserContext } from "../../../../contexts/UserContext";
import CadastroForm from "./CadastroForm";
import { useDataContext } from "../../../../contexts/DataContext";
import { useLocation } from "react-router-dom";

const Multiprofissional = () => {
  const { user } = useUserContext();
  const { escolas } = useDataContext();
  const location = useLocation();
  const previousData = location.state?.previousData || null;
  const isEditar = previousData !== null;

  const [formData, setFormData] = useState({
    nomeCompleto: "",
    email: "",
    cpf: "",
    matricula: "",
    escola: "",
    regional: null,
    setor: null,
    profile: "MULTIPROFISSIONAL",
  });

  useEffect(() => {
    if (previousData) {
      setFormData(previousData);
    }
  }, [previousData]);

  return (
    user && (
      <div>
        <CadastroForm
          titulo={"Equipe Multiprofissional"}
          formData={formData}
          setFormData={setFormData}
          label={"Escola"}
          placeholder={"Selecione uma Escola"}
          tituloSelect={"escola"}
          options={escolas}
          isEditar={isEditar}
        />
      </div>
    )
  );
};

export default Multiprofissional;
