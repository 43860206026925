import React, { useState } from "react";
import styled from "styled-components";

import CustomButton from "../button/Button";
import Tabela from "../table/Table";
import {
  Title,
  Tabs,
  Tab,
  DataTable,
  FieldWrapper,
  FieldLabel,
  FieldValue,
  Overlay,
  Content,
  ButtonsWrapper,
} from "./StyledModal";
import Modal from "./ModalGenerico";
import { StyledCopyIcon } from "../../utils/StyledUtils";
import { handleCopy } from "../../utils/HandleCopy";
import { useUserContext } from "../../contexts/UserContext";

export const GridRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 20px;
`;

const ModalInfoEscola = ({
  isOpen,
  data,
  onEdit,
  onCancel,
  handleDelete,
  closeText = "Fechar",
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const { user } = useUserContext();
  const canDelete = user.profile === "ADMIN";
  // TODO melhorar edição de escola
  const canEdit = canDelete || false; //user.profile === "AVALIADOR";

  if (!isOpen || !data) return null;

  const openConfirmDelete = () => setConfirmDelete(true);
  const closeConfirmDelete = () => setConfirmDelete(false);
  const confirmDeleteAction = () => {
    handleDelete(data.id);
    closeConfirmDelete();
  };

  return (
    <Overlay>
      <Content>
        {/* TODO aplicar textAlign diretamente? */}
        <Title>{data.name.toUpperCase()}</Title>
        <Tabs>
          <Tab $active={activeTab === 0} onClick={() => setActiveTab(0)}>
            Dados da Escola
          </Tab>
          <Tab $active={activeTab === 1} onClick={() => setActiveTab(1)}>
            Dados Analíticos
          </Tab>
        </Tabs>
        {activeTab === 0 && (
          <DataTable>
            <GridRow>
              <FieldWrapper>
                <FieldLabel>INEP</FieldLabel>
                <FieldValue>
                  {data.inep}
                  {"   "}
                  <StyledCopyIcon onClick={() => handleCopy(data.inep)} />
                </FieldValue>
              </FieldWrapper>
              <FieldWrapper>
                <FieldLabel>Município</FieldLabel>
                <FieldValue>{data.municipio}</FieldValue>
              </FieldWrapper>
              <FieldWrapper>
                <FieldLabel>Regional</FieldLabel>
                <FieldValue>{data.regional}</FieldValue>
              </FieldWrapper>
            </GridRow>
            <GridRow>
              <FieldWrapper>
                <FieldLabel>Ensino Fundamental I</FieldLabel>
                <FieldValue>{data.efi ? "Sim" : "Não"}</FieldValue>
              </FieldWrapper>
              <FieldWrapper>
                <FieldLabel>Ensino Fundamental II</FieldLabel>
                <FieldValue>{data.efii ? "Sim" : "Não"}</FieldValue>
              </FieldWrapper>
              <FieldWrapper>
                <FieldLabel>Ensino Médio</FieldLabel>
                <FieldValue>{data.em ? "Sim" : "Não"}</FieldValue>
              </FieldWrapper>
            </GridRow>
          </DataTable>
        )}
        {activeTab === 1 && (
          <DataTable>
            <Tabela />
          </DataTable>
        )}
        <ButtonsWrapper>
          {canEdit && (
            <CustomButton onClick={onEdit} style={{ width: "150px" }}>
              Editar
            </CustomButton>
          )}
          <CustomButton onClick={onCancel} style={{ width: "150px" }}>
            {closeText}
          </CustomButton>
          {canDelete && (
            <CustomButton
              variant="cancel"
              onClick={openConfirmDelete}
              style={{ width: "150px" }}
            >
              Deletar
            </CustomButton>
          )}
        </ButtonsWrapper>
        <Modal
          isOpen={confirmDelete}
          text={`Deseja mesmo deletar ${data.name}?`}
          onProceed={confirmDeleteAction}
          onCancel={closeConfirmDelete}
          textoOk="Deletar"
          textoCancelar="Cancelar"
        />
      </Content>
    </Overlay>
  );
};

export default ModalInfoEscola;
