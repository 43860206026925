import React, { useState, useEffect } from "react";

import { useUserContext } from "../../../../contexts/UserContext";
import CadastroForm from "./CadastroForm";
import { useLocation } from "react-router-dom";

const Administrador = () => {
  const { user } = useUserContext();
  const location = useLocation();
  const previousData = location.state?.previousData || null;
  const isEditar = previousData !== null;

  const [formData, setFormData] = useState({
    nomeCompleto: "",
    email: "",
    cpf: "",
    matricula: "",
    regional: null,
    escola: null,
    setor: "",
    profile: "ADMIN",
  });

  useEffect(() => {
    if (previousData) {
      setFormData(previousData);
    }
  }, [previousData]);

  return (
    user && (
      <div>
        <CadastroForm
          titulo={"Administrador"}
          formData={formData}
          setFormData={setFormData}
          label={"Setor"}
          placeholder={"Informe seu setor"}
          tituloSelect={"setor"}
          isEditar={isEditar}
        />
      </div>
    )
  );
};

export default Administrador;
