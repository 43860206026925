import React, { createContext, useContext, useState, useEffect } from 'react';

export const UserContext = createContext();

/**
 * Componente UserProvider que fornece o contexto de usuário e token para seus filhos.
 * Armazena o token e o perfil do usuário no localStorage e sincroniza essas informações.
 *
 * @param {Object} props - As props do componente.
 * @param {React.ReactNode} props.children - Os componentes filhos.
 * @returns {JSX.Element} - O componente UserProvider.
 */
export const UserProvider = ({ children }) => {

  const [token, setToken] = useState(() => {
    const storedToken = localStorage.getItem('token');
    return storedToken ? storedToken : null;
  });

  const [isDisabledUpdate, setIsDisabledUpdate] = useState(() => {
    const storedIsDisabledUpdate = localStorage.getItem('isDisabledUpdate');
    return storedIsDisabledUpdate ? storedIsDisabledUpdate : null;
  });

  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem('perfil');
    return storedUser ? JSON.parse(storedUser) : null;
  });

  useEffect(() => {
    if (user) {
      localStorage.setItem('token', token);
      localStorage.setItem('isDisabledUpdate', isDisabledUpdate);
      localStorage.setItem('perfil', JSON.stringify(user));
    } else {
      localStorage.removeItem('token');
      localStorage.removeItem('isDisabledUpdate');
      localStorage.removeItem('perfil');
    }
  }, [user, token, isDisabledUpdate]);


  return (
    <UserContext.Provider value={{ user, setUser, token, setToken, isDisabledUpdate, setIsDisabledUpdate }}>
      {children}
    </UserContext.Provider>
  );
};

/**
 * Hook customizado para acessar o contexto de usuário.
 *
 * @returns {Object} - O objeto de contexto com as propriedades do usuário e token.
 * @returns {Object|null} user - O perfil do usuário armazenado no contexto. Pode ser `null` se o usuário não estiver autenticado.
 * @returns {Function} setUser - Função para atualizar o estado do usuário no contexto.
 * @returns {String|null} token - O token de autenticação armazenado no contexto. Pode ser `null` se não houver token.
 * @returns {Function} setToken - Função para atualizar o estado do token no contexto.
 */
export const useUserContext = () => useContext(UserContext);
