import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { useDataContext } from "../../contexts/DataContext";
import { slugify } from "../../utils/slugify";
import {
  IconContainerTable,
  StyledEditIcon,
  StyledSearchIcon,
} from "../../utils/StyledUtils";
import Table from "../../components/table/Table";
import ModalInfoEscola from "../../components/modal/ModalInfoEscola";
import Tooltip from "../../components/tooltip/Tooltip";
import { useUserContext } from "../../contexts/UserContext";

const Link = styled.div`
  cursor: default;
  &:hover {
    color: #0392e0;
    cursor: pointer;
  }
`;

const Escolas = () => {
  const { escolas, mutationDeleteEscola } = useDataContext();
  const [escolasFiltrado, setEscolasFiltrado] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentEscola, setCurrentEscola] = useState(null);
  const { user } = useUserContext();
  const isAdmin = user.profile === "ADMIN";

  const navigate = useNavigate();

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleViewEscola = useCallback(
    (id) => {
      const escola = escolas.find((e) => e.id === id);
      setCurrentEscola(escola);
      setModalIsOpen(true);
    },

    [escolas]
  );

  const handleEditEscola = useCallback(
    (id) => {
      const escola = escolas.find((e) => e.id === id);
      setCurrentEscola(escola);
      navigate("cadastro?editar=true", {
        state: { previousData: escola || currentEscola },
      });
    },
    [currentEscola, escolas, navigate]
  );

  const handleDeleteEscola = async (id) => {
    try {
      await mutationDeleteEscola.mutateAsync(id);
    } catch (error) {
      console.error("Erro ao excluir escola:", error);
    } finally {
      closeModal();
    }
  };

  const handleEscolaClick = useCallback(
    (name) => {
      navigate(`/escolas/${slugify(name)}`);
    },
    [navigate]
  );

  useEffect(() => {
    if (escolas.length > 0) {
      const processedEscolas = escolas.map((escola) => ({
        regional: escola.regional,
        municipio: escola.municipio,
        nome: (
          <Link onClick={() => handleEscolaClick(escola.name)}>
            {escola.name.toUpperCase()}
          </Link>
        ),
        efi: escola.efi ? "EFI" : "-",
        efii: escola.efii ? "EFII" : "-",
        em: escola.em ? "EM" : "-",
        acoes: (
          <IconContainerTable>
            <StyledSearchIcon
              size={25}
              onClick={() => handleViewEscola(escola.id)}
            />
            {isAdmin && (
              <Tooltip text="Editar">
                <StyledEditIcon
                  size={25}
                  onClick={() => handleEditEscola(escola.id)}
                />
              </Tooltip>
            )}
          </IconContainerTable>
        ),
      }));
      setEscolasFiltrado(processedEscolas);
    }
  }, [escolas, handleEscolaClick, handleViewEscola, handleEditEscola, isAdmin]);

  const header = [
    "REGIONAL",
    "MUNICÍPIO",
    "NOME",
    "EFI",
    "EFII",
    "EM",
    "AÇÕES",
  ];
  const search = ["regional", "municipio", "nome"];

  return (
    <div>
      <Table
        data={escolasFiltrado}
        header={header}
        paginated={true}
        search={search}
        actionAdd={isAdmin}
      />
      <ModalInfoEscola
        isOpen={modalIsOpen}
        onEdit={handleEditEscola}
        onCancel={closeModal}
        data={currentEscola}
        handleDelete={handleDeleteEscola}
      />
    </div>
  );
};

export default Escolas;
