import React, { useState } from "react";

import CustomButton from "../button/Button";
import {
  Title,
  DataTable,
  FieldWrapper,
  FieldLabel,
  FieldValue,
  GridRow,
  Overlay,
  Content,
} from "./StyledModal";
import Modal from "./ModalGenerico";
import { StyledCopyIcon } from "../../utils/StyledUtils";
import { handleCopy } from "../../utils/HandleCopy";
import { formatCPF } from "../../utils/format";

const ModalInfoUser = ({
  isOpen,
  data,
  onCancel,
  handleEdit,
  handleDelete,
  closeText = "Fechar",
}) => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const formatName = (name) => {
    const words = name.toLowerCase().split(" ");
    const formatedName = words.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });
    return formatedName.join(" ");
  };

  if (!isOpen || !data) return null;

  // const openConfirmDelete = () => setConfirmDelete(true);
  const closeConfirmDelete = () => setConfirmDelete(false);
  const confirmDeleteAction = () => {
    handleDelete(data.id);
    closeConfirmDelete();
  };

  return (
    <Overlay>
      <Content style={{ textAlign: "left" }}>
        <Title>{formatName(data.nomeCompleto)}</Title>
        <DataTable>
          <GridRow>
            <FieldWrapper>
              <FieldLabel>E-mail</FieldLabel>
              <FieldValue>
                {data.email}{" "}
                <StyledCopyIcon onClick={() => handleCopy(data.email)} />
              </FieldValue>
            </FieldWrapper>
            <FieldWrapper>
              <FieldLabel>Tipo</FieldLabel>
              <FieldValue>{data.profile}</FieldValue>
            </FieldWrapper>
          </GridRow>
          <GridRow>
            <FieldWrapper>
              <FieldLabel>CPF</FieldLabel>
              <FieldValue>
                {formatCPF(data.cpf)}{" "}
                <StyledCopyIcon onClick={() => handleCopy(data.cpf)} />
              </FieldValue>
            </FieldWrapper>
            <FieldWrapper>
              <FieldLabel>Matrícula</FieldLabel>
              <FieldValue>
                {data.matricula}{" "}
                <StyledCopyIcon onClick={() => handleCopy(data.matricula)} />
              </FieldValue>
            </FieldWrapper>
          </GridRow>
        </DataTable>
        <div
          style={{
            display: "flex",
            gap: "50px",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <CustomButton onClick={onCancel} style={{ width: "150px" }}>
            {closeText}
          </CustomButton>
        </div>
        <Modal
          isOpen={confirmDelete}
          text={`Deseja mesmo deletar ${data.nomeCompleto}?`}
          onProceed={confirmDeleteAction}
          onCancel={closeConfirmDelete}
          textoOk="Deletar"
          textoCancelar="Cancelar"
        />
      </Content>
    </Overlay>
  );
};

export default ModalInfoUser;
