import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Outlet } from "react-router-dom";

import Sidebar from "../menu/sidebar/Sidebar";
import ProfileBar from "../menu/profileBar/ProfileBar";
import Breadcrumb from "../menu/breadcrumb/Breadcrumb";

const GridContainer = styled.div`
  display: grid;
  grid-template-areas:
    "sidebar header"
    "sidebar content";
  grid-template-columns: ${(props) => (props.$expanded ? "250px" : "80px")} 1fr;
  grid-template-rows: 50px auto;
  // height: 100vh;
  // width: 100vw;
  transition: grid-template-columns 0.3s;
`;

const SidebarContainer = styled.div`
  grid-area: sidebar;
`;

const RightContainer = styled.div`
  margin: 30px 40px;
`;

const HeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: space-between;
`;

const LeftHeaderElement = styled.div`
  justify-self: start;
`;

const RightHeaderElement = styled.div`
  justify-self: end;
`;

const ContentContainer = styled.div`
  grid-area: content;
  padding: 30px 0px 30px 0px;
`;

const LayoutSidebar = () => {
  const [expanded, setExpanded] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [wasExpanded, setWasExpanded] = useState(windowWidth > 1268);

  const handleToggle = useCallback(
    (value) => {
      if (value === undefined) {
        setExpanded(!expanded);
      } else {
        setExpanded(value);
      }
    },
    [expanded]
  );

  const handleResize = useCallback(() => {
    const newWidth = window.innerWidth;
    const shouldExpand = newWidth > 1268;

    if (shouldExpand !== wasExpanded) {
      handleToggle(shouldExpand);
      setWasExpanded(shouldExpand);
    }

    setWindowWidth(newWidth);
  }, [handleToggle, wasExpanded]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [wasExpanded, handleToggle, handleResize]);

  return (
    <GridContainer $expanded={expanded}>
      <SidebarContainer>
        <Sidebar expanded={expanded} onToggle={handleToggle} />
      </SidebarContainer>
      <RightContainer>
        <HeaderContainer>
          <LeftHeaderElement>
            <Breadcrumb />
          </LeftHeaderElement>
          <RightHeaderElement>
            <ProfileBar />
          </RightHeaderElement>
        </HeaderContainer>
        <ContentContainer>
          <Outlet />
        </ContentContainer>
      </RightContainer>
    </GridContainer>
  );
};

export default LayoutSidebar;
